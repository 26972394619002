import { useEffect } from "react";
import { Link } from "react-router-dom";

import { gsap } from "gsap";

import {
    faArrowRightLong,
  } from "@fortawesome/free-solid-svg-icons";
  
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function WorkflowSection(props) {

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.timeline({ scrollTrigger: {
        trigger: document.getElementById("stages"), 
        start: "top bottom",
        scrub: false, 
        once: true
      }})
      .fromTo(".stage-1", { x: -400, opacity: 0 }, { x: 0, opacity: 1})
      .fromTo(".stage-2", { x: -400, opacity: 0 }, { x: 0, opacity: 1})
      .fromTo(".stage-3", { x: -400, opacity: 0 }, { x: 0, opacity: 1})
      .fromTo(".stage-4", { x: -400, opacity: 0 }, { x: 0, opacity: 1})
    });
    return () => ctx.revert()
  }, [])

  return (
    <section id="workflow" className="container workflow-container" ref={props.workflowRef}>
      <div className="workflow-infos">
        <p className="section-header-red">JAK PRACUJEMY?</p>
        <p className="section-header">Poznaj nasz workflow</p>
        <p className="workflow-text">
          Jak nas postrzegać? Jako podwykonawcy white-label nasza współpraca
          unika wykorzystywania naszej nazwy firmy w jakiejkolwiek
          korespondencji z klientem. Od strony jednak przepływu pracy i
          informacji - działamy jak każda inna firma świadcząca usługi
          podwykonawcze.
        </p>
        <p className="workflow-text">
          Do każdej współpracy podchodzimy indywidualnie, więc etapy z prawej
          strony mogą się różnić w zależności od rodzaju projektu. Jeżeli chcesz dowiedzieć się więcej - kliknij w przycisk poniżej.
        </p>
        <Link to={"/jak-to-dziala"} className="workflow-red-button" aria-label="Dowiedz się więcej">
            <span>Dowiedz się więcej</span>
            <span>
              <FontAwesomeIcon icon={faArrowRightLong} />
            </span>
        </Link>
      </div>
      <div className="stages-display" id="stages">
        <div className="stage-1">
            <p className="stage-number">01</p>
            <p className="stage-title">Twój kontakt z klientem</p>
            <p className="stage-text">Kontaktujesz się z klientem, zbierasz wymagania i dzięki naszemu kalkulatorowi dokonujesz wstępnej wyceny.</p>
        </div>
        <div className="stage-2">
            <p className="stage-number">02</p>
            <p className="stage-title">Wycena i warunki realizacji</p>
            <p className="stage-text">Na podstawie danych zebranych od klienta ustalamy warunki realizacji oraz ostateczną wycenę naszej pracy.</p>
        </div>
        <div className="stage-3">
            <p className="stage-number">03</p>
            <p className="stage-title">Podpisanie umowy</p>
            <p className="stage-text">Jeśli klient zaakceptuje końcową cenę (wraz z Twoją marżą) podpisujesz umowę z nim i kolejną z nami.</p>
        </div>
        <div className="stage-4">
            <p className="stage-number">04</p>
            <p className="stage-title">Realizacja projektu</p>
            <p className="stage-text">Po podpisaniu umowy z nami przystępujemy do rzeczywistej realizacji projektu.</p>
        </div>
      </div>
    </section>
  );
}

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"

import axios from 'axios';

export default function WordPressPage() {

    const [isInWordPress, setIsInWordPress] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`https://wp.coderhino.cc/wp-json/wp/v2/pages?slug=${location.pathname}`)
          .then(response => {
            if (response.data.length) {
                setIsInWordPress(true)
            } else {
                navigate("/nie-znaleziono");
            }
          }) 
          .catch(error => console.error(error));
    }, [])
    
    return (
        <div className="container not-found-container">
            { isInWordPress ? (
                <>
                    <p>
                        WORDPRESS CONTENT
                    </p>
                </>
            ) : (<p>
                Ładowanie...
            </p>)}
        </div>
    )
}
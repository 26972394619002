import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { gsap } from "gsap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faBars,
  faX,
} from "@fortawesome/free-solid-svg-icons";

import useWindowDimensions from "../hooks/useWindowDimensions";
import useScrollPosition from "../hooks/useScrollPosition";

export default function NavBar() {
  const [showMenu, setShowMenu] = useState(false);
  const [path, setPath] = useState("");

  let location = useLocation();

  const { height, width } = useWindowDimensions();
  const scrollPostiion = useScrollPosition();

  useEffect(() => {
    window.scrollTo(0, 0);
    setPath(location.pathname);
    setShowMenu(false)
  }, [location]);

  function PriceButton() {
    return (
      <Link
        to="/kontakt"
        className="navigation-price-button"
        aria-label="Uzyskaj wycenę"
      >
        <span>Uzyskaj wycenę</span>
        <span>
          <FontAwesomeIcon icon={faArrowRightLong} />
        </span>
      </Link>
    );
  }

  function hideMenu() {
    let ctx = gsap.context(() => {
      gsap.timeline()
        .add("start")
        .fromTo(".navigation-mobile-clickable", { opacity: 1 }, { opacity: 0 }, "start")
        .to(".navigation-mobile-menu", { x: "100%" }, "start")
        .add(() => setShowMenu(false))
    });
    return () => ctx.revert()
  }

  useEffect(() => {
    let ctx = gsap.context(() => {
      if (showMenu) {
        gsap.timeline()
          .add("start")
          .fromTo(".navigation-mobile-clickable", { opacity: 0 }, { opacity: 1 }, "start")
          .to(".navigation-mobile-menu", { x: "-100%" }, "start")
      }
    });
  return () => ctx.revert()
  }, [showMenu])
  

  function generateNavigation() {
    return (
      <>
        <li key={1} className="list-element">
          <Link
            to="/co-robimy"
            className={`navigation-link ${
              path === "/co-robimy" ? "active-link" : ""
            }`}
            aria-label="Strona o tym co robimy"
          >
            Co robimy?
          </Link>
        </li>
        <li key={2} className="list-element">
          <Link
            to="/body-leasing"
            className={`navigation-link ${
              path === "/body-leasing" ? "active-link" : ""
            }`}
            aria-label="Strona o body-leasingu"
          >
            Body-leasing
          </Link>
        </li>
        <li key={4} className="list-element">
          <Link
            to="/kontakt"
            className={`navigation-link ${
              path === "/kontakt" ? "active-link" : ""
            }`}
            aria-label="Strona kontaktu"
          >
            Kontakt
          </Link>
        </li>
      </>
    );
  }

  return (
    <header className={scrollPostiion > 0 ? "navigation-fixed" : ""}>
      <nav className="container navigation">
        <div className="navigation-left">
          <Link
            to={"/"}
            className={!showMenu || width > 992 ? "visible" : "invisible"}
            aria-label="Strona główna"
          >
            <img
              src={window.location.origin + "/img/coderhino-logo-black.png"}
              alt="coderhino-logo"
              className="navigation-logo"
            />
          </Link>
          <ul className="navigation-list-desktop">{generateNavigation()}</ul>
        </div>
        <div className="navigation-right">
          <div className="navigation-mobile">
            {showMenu ? (
              <div className="navigation-mobile-container">
                <div
                  onClick={() => setShowMenu(false)}
                  className="navigation-mobile-clickable"
                ></div>
                <div className="navigation-mobile-menu">
                  <button
                    aria-label="Schowaj nawigację"
                    className="navigation-hide-button"
                    onClick={hideMenu}
                  >
                    <FontAwesomeIcon icon={faX} />
                  </button>
                  <Link to={"/"} className={showMenu ? "visible" : "invisible"} aria-label="Strona główna">
                    <img
                      src={
                        window.location.origin + "/img/coderhino-logo-black.png"
                      }
                      alt="coderhino-logo"
                      className="navigation-logo"
                    />
                  </Link>
                  <ul className="navigation-list-mobile">
                    {generateNavigation()}
                  </ul>
                  <PriceButton />
                </div>
              </div>
            ) : (
              <button
                aria-label="Pokaż menu"
                className="navigation-show-button"
                onClick={() => setShowMenu(true)}
              >
                <FontAwesomeIcon icon={faBars} size="xl" />
              </button>
            )}
          </div>
          <div className="navigation-desktop">
            <PriceButton />
          </div>
        </div>
      </nav>
    </header>
  );
}

import { useRef, useEffect } from "react";
import { gsap } from "gsap";

import ClientsSwiper from "./ClientsSwiper";

import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ClientSection() {

  const { height, width } = useWindowDimensions();

  const clients = useRef(null);

  function generateClients(display) {
    const clients = [
      <div id="client-1">
        <img
          src={window.location.origin + "/img/sample-client.png"}
          alt="client-logo"
        />
      </div>,
      <div id="client-2">
        <img
          src={window.location.origin + "/img/sample-client.png"}
          alt="client-logo"
        />
      </div>,
      <div id="client-3">
        <img
          src={window.location.origin + "/img/sample-client.png"}
          alt="client-logo"
        />
      </div>,
      <div id="client-4">
        <img
          src={window.location.origin + "/img/sample-client.png"}
          alt="client-logo"
        />
      </div>,
      <div id="client-5">
        <img
          src={window.location.origin + "/img/sample-client.png"}
          alt="client-logo"
        />
      </div>,
    ];

    if (display === "default") return clients;
    if (display === "swiper") {
      return <ClientsSwiper clients={clients} />;
    }
  }

  useEffect(() => {
    let ctx = gsap.context(() => {
      let timeline = gsap.timeline({ scrollTrigger: {
        trigger: document.getElementById("clients"), 
        start: "bottom bottom",
        scrub: false, 
        once: true
      } })
      for(const child of (clients.current.children)) {
        timeline.fromTo(document.getElementById(child.id), { opacity: 0, x: -100 }, { opacity: 1, x: 0, duration: .2 })
      }
    });
    return () => ctx.revert()
  }, [])

  return (
    <section id="clients" className="container clients-container" ref={clients}>
      {width > 992 ? generateClients("default") : generateClients("swiper")}
    </section>
  );
}

import { Link } from "react-router-dom";

export default function PageTitleSection(props) {
  return (
    <header className="page-title-section">
      <div className="container">
        <h1>{props.title}</h1>
        <ul className="breadcrumbs">
          <li>
            <Link to="/">Strona główna</Link>
          </li>
          <li className="active">{props.title}</li>
        </ul>
      </div>
    </header>
  );
}

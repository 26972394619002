import { useEffect } from "react";

export default function NotFoundPage() {

    useEffect(() => {
        document.title = `CODERHINO - Nie znaleziono`;
    }, [])
    
    return (
        <div className="container not-found-container">   
            <p>
                O nie, coś poszło nie tak!
            </p>
            <p>
                Nie powinieneś się tu znaleźć! Jeśli trafiłeś tutaj z dowolnego odnośnika na naszej stronie - możliwe, że już wygasł.                
            </p>
        </div>
    )
}
import { useEffect } from "react";

import PageTitleSection from "../components/PageTitleSection";

import ContactForm from "../components/ContactForm";

export default function ContactPage() {

    useEffect(() => {
        document.title = `CODERHINO - Kontakt`;
    }, []);

    return (
        <>
            <PageTitleSection title="Kontakt" />
            <div className="container container-padding contact-page-container">
                <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1258.290180170807!2d20.621469012711575!3d50.894476695124204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471827d72d68966d%3A0xcab0543ce766ba2a!2sKarola%20Olszewskiego%206%2C%2025-663%20Kielce!5e0!3m2!1spl!2spl!4v1683803067537!5m2!1spl!2spl" title="Google Map" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
                </div>
                <ContactForm />
            </div>
        </>
    )
}
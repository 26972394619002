import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
} from "react-router-dom";

import { useCookies } from "react-cookie";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import MainPage from "./pages/MainPage";
import AboutPage from "./pages/AboutPage";
import NotFoundPage from "./pages/NotFoundPage";
import TeamPage from "./pages/TeamPage";
import ContactPage from "./pages/ContactPage";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import HowItsWorkingPage from "./pages/HowItsWorkingPage";
import CarrerPage from "./pages/CarrerPage";
import BodyLeasingPage from "./pages/BodyLeasingPage";

import WebsitesPage from "./pages/about-subpages/WebsitesPage";
import OptimalizationPage from "./pages/about-subpages/OptimalizationPage";
import HandlingPage from "./pages/about-subpages/HandlingPage";
import ECommercePage from "./pages/about-subpages/ECommercePage";
import BillingPage from "./pages/BillingPage";
import ConsultationPage from "./pages/ConsultationPage";
import AboutUsPage from "./pages/AboutUsPage";
import FAQPage from "./pages/FAQPage";
import CookiesPage from "./pages/CookiesPage";
import PrivacyPage from "./pages/PrivacyPage";
import FunctionalitiesPage from "./pages/about-subpages/FunctionalitiesPage";

import AgreementModal from "./components/modals/AgreementModal";
import WordPressPage from "./pages/WordPressPage";
import PostPage from "./pages/PostPage";

function AppLayout() {

  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [loadAnalyticsScripts, setLoadAnalyticsScripts] = useState(false);

  const [cookies, setCookies, removeCookies] = useCookies();

  useEffect(() => {
    if (cookies["agreement-cookie"] === undefined) {
      setShowAgreementModal(true);
    } else {
      if (cookies["agreement-cookie"] === "true") {
        setLoadAnalyticsScripts(true)
      }
    }
  },[]);

  function rejectCookies() {
    setCookies("agreement-cookie", "false", { path: '/' });
    setShowAgreementModal(false);
  }

  function acceptCookies() {
    setCookies("agreement-cookie", "true", { path: '/' });
    setShowAgreementModal(false);
    setLoadAnalyticsScripts(true);
  }

  return(
    <>
      { showAgreementModal ? (
        <AgreementModal rejectCookies={rejectCookies} acceptCookies={acceptCookies} />
      ): (null)}
      { loadAnalyticsScripts ? (
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-EE0ZGRT7NR"></script>
          <script type="javascript/text">
            {`window.dataLayer = window.dataLayer || []; function gtag() { dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', 'G-EE0ZGRT7NR');`}
          </script>
        </Helmet>
      ) : (null) }
      <NavBar />
      <Outlet />
      <Footer />
    </>
  )
}

export const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        name: "Strona główna",
        element: <MainPage />,
      },
      {
        path: "/co-robimy",
        name: "Co robimy?",
        element: <AboutPage />,
      },
      {
        path: "/co-robimy/sklepy-internetowe",
        name: "Sklepy internetowe",
        element: <ECommercePage />
      },
      {
        path: "/co-robimy/obsluga-stron-internetowych",
        name: "Obsługa stron internetowych",
        element: <HandlingPage />
      },
      {
        path: "/co-robimy/strony-internetowe",
        name: "Strony internetowe",
        element: <WebsitesPage />
      },
      {
        path: "/co-robimy/funkcjonalności",
        name: "Dedykowane funkcjonalności",
        element: <FunctionalitiesPage />
      },
      {
        path: "/co-robimy/optymalizacja",
        name: "Optymaliacja stron WWW",
        element: <OptimalizationPage />
      },
      {
        path: "/zespol",
        name: "Zespół",
        element: <TeamPage />,
      },
      {
        path: "/kariera",
        name: "Kariera",
        element: <CarrerPage />,
      },
      {
        path: "/kontakt",
        name: "Kontakt",
        element: <ContactPage />,
      },
      {
        path: "/jak-to-dziala",
        name: "Jak to działa?",
        element: <HowItsWorkingPage />,
      },
      {
        path: "/body-leasing",
        name: "Body-leasing?",
        element: <BodyLeasingPage />,
      },
      {
        path: "/o-nas",
        name: "O Nas",
        element: <AboutUsPage />,
      },
      {
        path: "/faq",
        name: "F.A.Q",
        element: <FAQPage />,
      },
      {
        path: "/rozliczenia",
        name: "Sposoby rozliczenia",
        element: <BillingPage />,
      },
      {
        path: "/konsultacja-projektu",
        name: "Konsultacja projektu",
        element: <ConsultationPage />,
      },
      {
        path: "/cookies",
        name: "Cookies",
        element: <CookiesPage />,
      },
      {
        path: "/polityka-prywatnosci",
        name: "Polityka prywatności",
        element: <PrivacyPage />,
      },
      {
        path: "/p/:name",
        name: "Post",
        element: <PostPage />,
      },
      {
        path: "/nie-znaleziono",
        name: "Nie znaleziono",
        element: <NotFoundPage />,
      },
      {
        path: "/*",
        element: <WordPressPage />,
      },
    ]  
  }
]);

function App() {
  gsap.registerPlugin(ScrollTrigger);
  return (
    <RouterProvider router={router} />
  );
}

export default App;

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

export default function ServiceSwiper(props) {

    function generateSlides() {

        let elements = [];

        for (const service of props.services) {
            elements.push(
                <SwiperSlide>
                    { service }
                </SwiperSlide>
            )
        }

        return elements;
    }

  return (
  <Swiper slidesPerView={2}
  initialSlide={1}
  spaceBetween={10}
  centeredSlides={true}
  className="service-swiper">
    { generateSlides() }
  </Swiper> 
  )
}

import { Link } from "react-router-dom";

export default function SerivcePageTitleSection(props) {
  return (
    <header className="page-title-section" ref={props.headerRef}>
      <div className="container">
        <h1>{props.title}</h1>
        <ul className="breadcrumbs">
          <li>
            <Link to="/">Strona główna</Link>
          </li>
          <li>
            <Link to="/co-robimy">Co robimy?</Link>
          </li>
          <li className="active">{props.title}</li>
        </ul>
      </div>
    </header>
  );
}

import { useEffect } from "react";

import PageTitleSection from "../components/PageTitleSection"

export default function BillingPage() {

    useEffect(() => {
        document.title = `CODERHINO - Sposoby rozliczenia`;
    }, []);

    return (
        <>
            <PageTitleSection title="Sposoby rozliczenia" />
            <div className="container">
                BILLING PAGE
            </div>
        </>
    )
}
import { useEffect, useRef } from "react";

import AboutSection from "../components/main-page/AboutSection";
import HeaderSection from "../components/main-page/HeaderSection";
import ClientSection from "../components/main-page/ClientSection";
import RealizationsSection from "../components/main-page/RealizationsSection";
import StatsSection from "../components/main-page/StatsSection";
import TeamSection from "../components/main-page/TeamSection"
import WorkflowSection from "../components/main-page/WorkflowSection";
import ContactSection from "../components/main-page/ContactSection";
import PostsSection from "../components/main-page/PostsSection";


export default function MainPage() {

    const aboutRef = useRef(null);
    const workflowRef = useRef(null);

    useEffect(() => {
        document.title = `CODERHINO`;
    }, []);

    return (
        <main>
            <HeaderSection aboutRef={aboutRef} />
            <ClientSection />
            <AboutSection aboutRef={aboutRef} workflowRef={workflowRef} />
            <WorkflowSection workflowRef={workflowRef} />
            <StatsSection />
            <RealizationsSection />
            <PostsSection />
        </main>
    )
}
import { useEffect, useRef } from "react";

import SerivcePageTitleSection from "../../components/about-subpages/ServicePageTitle"
import ServicePageContent from "../../components/about-subpages/ServicePageContent"

export default function FunctionalitiesPage() {

    const pageData = {
        title: "Dedykowane funkcjonalności",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
        Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
        Praesent sollicitudin sit amet nisi eu commodo. 
        Vivamus lectus justo, dignissim.`,
        projects: [
            {
                id: 2,
                name: "Aplikacja do zamawiania wywozu nieczystości",
                image: "/img/screens/slonik.png",
                link: "https://slonik24.pl",
                category: ["dedykowane rozwiązania", "sklepy internetowe"],
              },
              {
                id: 6,
                name: "Aplikcja do administracji turnieju w grę online",
                image: "/img/screens/no-screen.png",
                link: "https://www.google.com",
                category: ["dedykowane rozwiązania"],
              },
        ], 
        faq: [
            {
                question: "Question 1",
                answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
                Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
                Praesent sollicitudin sit amet nisi eu commodo. 
                Vivamus lectus justo, dignissim.`,
            },
            {
                question: "Question 2",
                answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
                Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
                Praesent sollicitudin sit amet nisi eu commodo. 
                Vivamus lectus justo, dignissim.`,
            },
            {
                question: "Question 3",
                answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
                Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
                Praesent sollicitudin sit amet nisi eu commodo. 
                Vivamus lectus justo, dignissim.`,
            },
        ]
    }

    const headerRef = useRef(null);

    useEffect(() => {
        document.title = `CODERHINO - ${pageData.title}`;
    }, []); 

    return (
        <>
            <SerivcePageTitleSection title={pageData.title} headerRef={headerRef} />
            <ServicePageContent pageData={pageData} headerRef={headerRef} />
        </>
    )
}
import { useState, useEffect } from "react";

import { gsap } from "gsap";

export default function StepsSection() {
  const [hoveredStep, setHoveredStep] = useState(1);

  function generateInfo() {
    if (hoveredStep === 1)
      return (
        <>
          <p className="info-title">Rozmowa wstępna</p>
          <p className="info-text">
            Po rozmowie wstępnej Twoja kandydatura zostanie przekazana do działu
            technicznego. Po <span className="bold-span">wstępnym pozytywnym rozpatrzeniu</span> otrzymasz
            zaproszenie na rozmowę techniczną. W jej trakcie rekruter techniczny
            pozna Twoje mocne i słabe strony, a także oceni Twoją przydatność do
            zespołu. 
          </p>
          <p className="info-text">
          Po zakończeniu rozmowy otrzymasz zadanie rekrutacyjne. Na
            jego rozwiązanie nie powinieneś przeznaczyć więcej niż 30 minut.
            Jego poziom i treść będzie dostosowana do umiejętności, które
            zdaniem rekrutera będą wymagane do weryfikacji.
          </p>
        </>
      );
    if (hoveredStep === 2)
      return (
        <>
          <p className="info-title">Zadanie + rozmowa techniczna</p>
          <p className="info-text">
            Po rozmowie wstępnej Twoja kandydatura zostanie przekazana do działu
            technicznego. Po <span className="bold-span">wstępnym pozytywnym rozpatrzeniu</span> otrzymasz
            zaproszenie na rozmowę techniczną. W jej trakcie rekruter techniczny
            pozna Twoje mocne i słabe strony, a także oceni Twoją przydatność do
            zespołu. 
          </p>
          <p className="info-text">
          Po zakończeniu rozmowy otrzymasz zadanie rekrutacyjne. Na
            jego rozwiązanie nie powinieneś przeznaczyć więcej niż 30 minut.
            Jego poziom i treść będzie dostosowana do umiejętności, które
            zdaniem rekrutera będą wymagane do weryfikacji.
          </p>
        </>
      );
    if (hoveredStep === 3)
      return (
        <>
          <p className="info-title">Podpisanie umowy</p>
          <p className="info-text">
            Po rozmowie wstępnej Twoja kandydatura zostanie przekazana do działu
            technicznego. Po <span className="bold-span">wstępnym pozytywnym rozpatrzeniu</span> otrzymasz
            zaproszenie na rozmowę techniczną. W jej trakcie rekruter techniczny
            pozna Twoje mocne i słabe strony, a także oceni Twoją przydatność do
            zespołu. 
          </p>
          <p className="info-text">
          Po zakończeniu rozmowy otrzymasz zadanie rekrutacyjne. Na
            jego rozwiązanie nie powinieneś przeznaczyć więcej niż 30 minut.
            Jego poziom i treść będzie dostosowana do umiejętności, które
            zdaniem rekrutera będą wymagane do weryfikacji.
          </p>
        </>
      );
  }

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.timeline({ scrollTrigger: {
        trigger: document.getElementById("steps"), 
        start: "top bottom",
        scrub: false, 
        once: true
      }})
      .fromTo(document.getElementById("step-3"), { y: -200, opacity: 0 }, { y: 0, opacity: 1 })
      .fromTo(document.getElementById("step-2"), { y: -200, opacity: 0 }, { y: 0, opacity: 1 })
      .fromTo(document.getElementById("step-1"), { y: -200, opacity: 0 }, { y: 0, opacity: 1 } )
    });
    return () => ctx.revert()
  }, [])

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.fromTo(document.getElementsByClassName("step-info"), { x: -10 }, { x: 0})
    });
    return () => ctx.revert()
  }, [hoveredStep])

  return (
    <section id="steps" className="container steps-container">
      <p className="section-header">3-etapowy proces rekrutacji:</p>
      <div className="steps-display">
        <div className="steps-buttons">
          <div className={`steps-button ${hoveredStep === 1 ? "active-step-button" : ""}`} 
          id="step-1"
          onMouseEnter={() => setHoveredStep(1)}>
            <p className="step-text">
              <span className="step-number-purple">1</span>
              <span className="mobile-hide">Rozmowa wstępna</span>
            </p>
          </div>
          <div className={`steps-button ${hoveredStep === 2 ? "active-step-button" : ""}`}
          id="step-2"
          onMouseEnter={() => setHoveredStep(2)}>
            <p className="step-text">
              <span className="step-number-blue">2</span>
              <span className="mobile-hide">Zadanie + rozmowa techniczna</span>
            </p>
          </div>
          <div className={`steps-button ${hoveredStep === 3 ? "active-step-button" : ""}`}
          id="step-3"
          onMouseEnter={() => setHoveredStep(3)}>
            <p className="step-text">
              <span className="step-number-green">3</span>
              <span className="mobile-hide">Podpisanie umowy</span>
            </p>
          </div>
        </div>
        <div className="step-info">{generateInfo()}</div>
      </div>
    </section>
  );
}

import { useState, useEffect } from "react"

import { gsap } from "gsap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEye } from "@fortawesome/free-regular-svg-icons";

import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function RealizationDisplay(props) {

    const [showLinks, setShowLinks] = useState(false);

    const { height, width } = useWindowDimensions();

    function generateTags() {

        let elements = [];

        for (let index = 0; index < props.realization.category.length; index++) {
            elements.push(
                <span>
                    { props.realization.category[index].toUpperCase() }
                    { index < props.realization.category.length - 1 ? (
                        <>
                            ,&nbsp;
                        </>
                    ) : null}
                </span>
            ) 
        }

        return elements;
    }

    // useEffect(() => {
    //     gsap.fromTo(`#realization-${props.realization.id}`, 
    //     { opacity: 0 }, 
    //     { opacity: 1, duration: 1, scrollTrigger: { trigger: `#realization-${props.realization.id}`}
    //     })
    // }, [])

    return (
        <div id={`realization-${props.realization.id}`}>
            <div className="realization-links-container">
                <div className="realization-photo"
                // onMouseEnter={() => {if(width > 992) setShowLinks(true)}}
                // onClick={() => {if(width <= 992) setShowLinks(!showLinks)}}
                >
                    <img src={props.realization.image} alt="realization-image" />
                </div>
                { showLinks && props.realization.link ? (
                    <div className="realization-links"
                    onMouseLeave={() => {if(width > 992) setShowLinks(false)}}
                    onClick={() => {if(width <= 992) setShowLinks(!showLinks)}}>
                        <button className="realization-link" 
                        onClick={() => props.showModalFunction(props.realization)}>
                            <FontAwesomeIcon icon={faEye} />
                        </button>
                    </div>
                ) : null}
            </div>
            <div className="realization-info">
                <p className="realization-tags">
                    { generateTags() }
                </p>
                <p className="realization-name">
                    { props.realization.name }
                </p>
            </div>
        </div>
    )
}
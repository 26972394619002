import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faSackDollar,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import OffersModal from "../modals/OffersModal";

export default function OffersSection() {
  const [offers, setOffers] = useState([]);
  const [workTimeFilter, setWorkTimeFilter] = useState([]);
  const [salaryFilter, setSalaryFilter] = useState("any");

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    setOffers([
      {
        id: 1,
        title: "WordPress Developer",
        position: "dowolny poziom, zdalnie",
        salary: {
          value: "Zleceniowe",
          type: "flexible",
        },
        workTime: {
          value: "nienormowany",
          type: "freelance",
        },
      },
      {
        id: 2,
        title: "Project Manager",
        position: "staż/praktyki, zdalnie",
        salary: {
          value: "23,50zł/h",
          type: "constant",
        },
        workTime: {
          value: "10-15h/mies.",
          type: "freelance",
        },
      },
    ]);
  }, []);

  function generateOffers() {
    return offers
      .filter(
        (offer) =>
          (workTimeFilter.includes(offer.workTime.type) ||
            workTimeFilter.length == 0) &&
          (offer.salary.type === salaryFilter || salaryFilter === "any")
      )
      .map((offer) => (
        <div key={offer.id} className="offer-display">
          <div>
            <p className="offer-title">{offer.title}</p>
            <p className="offer-position">{offer.position}</p>
          </div>
          <div className="text-icon">
            <div className="dollar-icon">
              <svg
                width="29"
                height="28"
                viewBox="0 0 29 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_30_655)">
                  <path
                    d="M14.6091 7.8401C11.1309 7.8401 8.11941 10.2026 7.36489 13.523L4.89395 24.3971C4.87582 24.4769 4.86667 24.5584 4.86667 24.6401C4.86667 25.2587 5.37942 25.7601 6.01192 25.7601H23.2062C23.2897 25.7601 23.3731 25.7512 23.4546 25.7334C24.0721 25.5992 24.4614 25.001 24.3242 24.3971L21.8532 13.523C21.0987 10.2026 18.0872 7.8401 14.6091 7.8401ZM14.6091 5.6001C19.1608 5.6001 23.1018 8.69176 24.0892 13.0371L26.5601 23.9112C26.9718 25.7227 25.8038 27.5175 23.9515 27.9201C23.7068 27.9733 23.4569 28.0001 23.2062 28.0001H6.01192C4.11441 28.0001 2.57617 26.4958 2.57617 24.6401C2.57617 24.3949 2.60361 24.1505 2.65799 23.9112L5.12893 13.0371C6.11634 8.69176 10.0573 5.6001 14.6091 5.6001Z"
                    fill="white"
                  />
                  <path
                    d="M16.8996 12.3201C17.5321 12.3201 18.0449 12.8215 18.0449 13.4401C18.0449 14.0586 17.5321 14.5601 16.8996 14.5601H14.0365C13.7203 14.5601 13.4639 14.8108 13.4639 15.1201C13.4639 15.4294 13.7203 15.6801 14.0365 15.6801H15.1818C16.763 15.6801 18.0449 16.9337 18.0449 18.4801C18.0449 20.0265 16.763 21.2801 15.1818 21.2801H12.3186C11.6861 21.2801 11.1734 20.7786 11.1734 20.1601C11.1734 19.5415 11.6861 19.0401 12.3186 19.0401H15.1818C15.498 19.0401 15.7544 18.7894 15.7544 18.4801C15.7544 18.1708 15.498 17.9201 15.1818 17.9201H14.0365C12.4553 17.9201 11.1734 16.6665 11.1734 15.1201C11.1734 13.5737 12.4553 12.3201 14.0365 12.3201H16.8996Z"
                    fill="white"
                  />
                  <path
                    d="M13.4639 11.2001C13.4639 10.5815 13.9767 10.0801 14.6092 10.0801C15.2417 10.0801 15.7544 10.5815 15.7544 11.2001V13.4401C15.7544 14.0587 15.2417 14.5601 14.6092 14.5601C13.9767 14.5601 13.4639 14.0587 13.4639 13.4401V11.2001Z"
                    fill="white"
                  />
                  <path
                    d="M15.7544 22.4001C15.7544 23.0187 15.2417 23.5201 14.6092 23.5201C13.9767 23.5201 13.4639 23.0187 13.4639 22.4001V20.1601C13.4639 19.5416 13.9767 19.0401 14.6092 19.0401C15.2417 19.0401 15.7544 19.5416 15.7544 20.1601V22.4001Z"
                    fill="white"
                  />
                  <path
                    d="M13.3819 6.30416C13.6168 6.87848 13.3312 7.53029 12.7439 7.76001C12.1567 7.98974 11.4902 7.7104 11.2553 7.13608L8.96476 1.53608C8.59255 0.626073 9.5106 -0.279483 10.4443 0.0766958C11.3019 0.403851 11.9411 0.560122 12.3186 0.560122C12.4126 0.560122 12.4905 0.54048 12.6605 0.469805C12.7057 0.451041 12.7587 0.428134 12.8831 0.374309C13.4755 0.120442 13.9434 0.000121694 14.6091 0.000121694C15.2733 0.000121694 15.7486 0.120762 16.3429 0.372178C16.4937 0.436752 16.5539 0.462435 16.6072 0.483908C16.7541 0.543038 16.8247 0.560122 16.8996 0.560122C17.255 0.560122 17.9 0.402444 18.7822 0.0735765C19.7147 -0.274052 20.6241 0.629907 20.2534 1.53608L17.9629 7.13608C17.728 7.7104 17.0615 7.98974 16.4743 7.76001C15.887 7.53029 15.6014 6.87848 15.8363 6.30416L17.2774 2.78085C17.1457 2.79368 17.0199 2.80012 16.8996 2.80012C16.4817 2.80012 16.1353 2.71632 15.7351 2.55518C15.6561 2.52336 15.5695 2.48642 15.4335 2.42807C15.1019 2.2878 14.9141 2.24012 14.6091 2.24012C14.307 2.24012 14.1296 2.28574 13.8025 2.42593C13.6902 2.47462 13.619 2.50536 13.5564 2.53138C13.1274 2.70964 12.7686 2.80012 12.3186 2.80012C12.1976 2.80012 12.0719 2.79398 11.9412 2.78177L13.3819 6.30416Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_30_655">
                    <rect
                      width="28.6313"
                      height="28"
                      fill="white"
                      transform="translate(0.293213)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>
              <p className="category-value">
                {Number.isInteger(offer.salary.value)
                  ? offer.salary.value + " PLN brutto"
                  : offer.salary.value}
              </p>
              <p className="category-name">Wynagrodzenie</p>
            </div>
          </div>
          <div className="text-icon">
            <div className="clock-icon">
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.5257 0H14.4677C14.3288 0.0189345 14.1897 0.037069 14.0506 0.0552071C13.7529 0.0940161 13.4551 0.132842 13.1588 0.179555C9.43344 0.765537 6.32434 2.48587 3.83451 5.3078C1.79656 7.61776 0.5847 10.3196 0.14011 13.3666C0.10459 13.6108 0.0749798 13.8558 0.0453803 14.1008C0.0305006 14.2239 0.0156236 14.347 0 14.47V16.5282C0.0188646 16.667 0.036935 16.8059 0.0550062 16.9449C0.0938753 17.2437 0.132748 17.5426 0.179534 17.8403C0.765447 21.5576 2.48073 24.6634 5.29323 27.1511C7.6108 29.2008 10.3196 30.4534 13.3856 30.8526C17.6744 31.4107 21.5763 30.3885 24.9947 27.7268C28.2997 25.1529 30.2831 21.7571 30.8441 17.6043C31.4306 13.261 30.3553 9.31686 27.6258 5.87741C25.0566 2.64056 21.6854 0.747339 17.598 0.136486C17.3501 0.0993241 17.1011 0.0695115 16.8519 0.0396808C16.7432 0.0266615 16.6344 0.0136387 16.5257 0ZM15.5182 2.41369C22.7384 2.42824 28.5848 8.28745 28.5805 15.5049C28.5763 22.735 22.7008 28.5991 15.4757 28.5839C8.25555 28.5693 2.40916 22.7095 2.4134 15.4927C2.41765 8.26258 8.29315 2.39852 15.5182 2.41369ZM16.7034 12.3072C16.7037 12.0666 16.704 11.8261 16.704 11.5856L16.7052 11.5862C16.7052 11.2808 16.7055 10.9754 16.7057 10.6701C16.7064 9.93693 16.707 9.20379 16.704 8.47064C16.701 7.72573 16.1975 7.1992 15.5006 7.19798C14.8025 7.19677 14.2936 7.7227 14.2924 8.46518C14.2903 9.82535 14.2904 11.1855 14.2906 12.5457C14.2907 13.5349 14.2908 14.5241 14.29 15.5133C14.29 15.9446 14.4416 16.3056 14.7867 16.5652C16.3886 17.7711 17.9929 18.9734 19.6002 20.1727C19.8889 20.388 20.2213 20.4572 20.5743 20.3656C21.0565 20.24 21.3737 19.9282 21.472 19.4447C21.5745 18.9376 21.3974 18.5142 20.977 18.1988C20.8129 18.0757 20.6488 17.9527 20.4846 17.8296C19.2862 16.9312 18.0875 16.0326 16.8963 15.1251C16.7974 15.0499 16.7143 14.8861 16.7125 14.7617C16.7012 13.9437 16.7023 13.1254 16.7034 12.3072Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <p className="category-value">{offer.workTime.value}</p>
              <p className="category-name">Czas pracy</p>
            </div>
          </div>
          <Link className="offer-button" to="/kontakt">
            Zgłoś się
            <FontAwesomeIcon icon={faArrowRightLong} />
          </Link>
        </div>
      ));
  }

  function showModalFunction(data) {
    setModalData(data);
    setShowModal(true);
  }

  function hideModalFunction() {
    setShowModal(false);
    setModalData({});
  }

  return (
    <section id="offers" className="container offers-container">
      {showModal ? (
        <OffersModal
          modalData={modalData}
          hideModalFunction={hideModalFunction}
        />
      ) : null}
      <div className="offers-header">
        <div>
          <p className="section-header">
            Aktualnie mamy {offers.length} oferty pracy
          </p>
        </div>
        <div className="offers-filters">
          <div>
            <input
              type="checkbox"
              id="full-time"
              checked={workTimeFilter.includes("full-time")}
              onChange={() => {
                workTimeFilter.includes("full-time")
                  ? setWorkTimeFilter(
                      workTimeFilter.filter((value) => value !== "full-time")
                    )
                  : setWorkTimeFilter(workTimeFilter.concat("full-time"));
              }}
            />
            <label className="full-time-label" htmlFor="full-time">
              {workTimeFilter.includes("full-time") ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.3325 6.86729C22.35 4.79073 20.794 3.04698 18.8337 1.82354C16.1072 0.12667 12.8778 -0.412392 9.74698 0.309483C6.61621 1.02667 3.95082 2.92042 2.24911 5.63917C0.542697 8.35792 0.00209773 11.5735 0.72603 14.7001C1.44996 17.822 3.34911 20.4798 6.07091 22.1767C7.98886 23.372 10.1889 24.0048 12.4406 24.0048H12.5863C14.8286 23.9767 17.0098 23.3298 18.8996 22.1392C19.4496 21.7923 19.6094 21.0704 19.2615 20.522C18.9137 19.9735 18.1897 19.8142 17.6397 20.161C16.1214 21.122 14.3632 21.6423 12.5581 21.6657C10.706 21.6892 8.89613 21.1782 7.31664 20.1985C5.12134 18.8298 3.59356 16.6923 3.01535 14.1798C2.43714 11.6673 2.86962 9.07979 4.24227 6.89073C7.07219 2.37667 13.0611 0.998545 17.588 3.82042C19.1675 4.8048 20.4179 6.20636 21.2077 7.87511C21.9786 9.50167 22.2795 11.3064 22.0773 13.0876C22.0068 13.7298 22.4675 14.311 23.1162 14.3814C23.7603 14.4517 24.3432 13.9923 24.4137 13.3454C24.6628 11.1282 24.2867 8.88761 23.3325 6.86729Z"
                    fill="#AF272F"
                  />
                  <path
                    d="M16.338 7.93135L10.6688 13.5845L8.56748 11.4892C8.10679 11.0298 7.36406 11.0298 6.90337 11.4892C6.44269 11.9485 6.44269 12.6892 6.90337 13.1485L9.83671 16.0735C10.067 16.3032 10.3679 16.4157 10.6688 16.4157C10.9696 16.4157 11.2705 16.3032 11.5008 16.0735L17.9974 9.59072C18.4581 9.13135 18.4581 8.39072 17.9974 7.93135C17.5367 7.47666 16.794 7.47666 16.338 7.93135Z"
                    fill="#AF272F"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.9162 6.86729C21.9337 4.79073 20.3777 3.04698 18.4175 1.82354C15.691 0.12667 12.4615 -0.412392 9.33072 0.309483C6.19995 1.02667 3.53456 2.92042 1.83285 5.63917C0.126437 8.35792 -0.414162 11.5735 0.309771 14.7001C1.0337 17.822 2.93285 20.4798 5.65465 22.1767C7.5726 23.372 9.7726 24.0048 12.0243 24.0048H12.17C14.4124 23.9767 16.5936 23.3298 18.4833 22.1392C19.0333 21.7923 19.1931 21.0704 18.8453 20.522C18.4974 19.9735 17.7735 19.8142 17.2235 20.161C15.7051 21.122 13.947 21.6423 12.1418 21.6657C10.2897 21.6892 8.47987 21.1782 6.90038 20.1985C4.70508 18.8298 3.1773 16.6923 2.59909 14.1798C2.02088 11.6673 2.45336 9.07979 3.82601 6.89073C6.65593 2.37667 12.6448 0.998545 17.1718 3.82042C18.7512 4.8048 20.0017 6.20636 20.7914 7.87511C21.5624 9.50167 21.8632 11.3064 21.6611 13.0876C21.5906 13.7298 22.0513 14.311 22.7 14.3814C23.344 14.4517 23.9269 13.9923 23.9974 13.3454C24.2466 11.1282 23.8705 8.88761 22.9162 6.86729Z"
                    fill="#AF272F"
                    fill-opacity="0.4"
                  />
                </svg>
              )}
              Full-time
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="freelance"
              checked={workTimeFilter.includes("freelance")}
              onChange={() => {
                workTimeFilter.includes("freelance")
                  ? setWorkTimeFilter(
                      workTimeFilter.filter((value) => value !== "freelance")
                    )
                  : setWorkTimeFilter(workTimeFilter.concat("freelance"));
              }}
            />
            <label className="freelance-label" htmlFor="freelance">
              {workTimeFilter.includes("freelance") ? (
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.3325 6.86729C22.35 4.79073 20.794 3.04698 18.8337 1.82354C16.1072 0.12667 12.8778 -0.412392 9.74698 0.309483C6.61621 1.02667 3.95082 2.92042 2.24911 5.63917C0.542697 8.35792 0.00209773 11.5735 0.72603 14.7001C1.44996 17.822 3.34911 20.4798 6.07091 22.1767C7.98886 23.372 10.1889 24.0048 12.4406 24.0048H12.5863C14.8286 23.9767 17.0098 23.3298 18.8996 22.1392C19.4496 21.7923 19.6094 21.0704 19.2615 20.522C18.9137 19.9735 18.1897 19.8142 17.6397 20.161C16.1214 21.122 14.3632 21.6423 12.5581 21.6657C10.706 21.6892 8.89613 21.1782 7.31664 20.1985C5.12134 18.8298 3.59356 16.6923 3.01535 14.1798C2.43714 11.6673 2.86962 9.07979 4.24227 6.89073C7.07219 2.37667 13.0611 0.998545 17.588 3.82042C19.1675 4.8048 20.4179 6.20636 21.2077 7.87511C21.9786 9.50167 22.2795 11.3064 22.0773 13.0876C22.0068 13.7298 22.4675 14.311 23.1162 14.3814C23.7603 14.4517 24.3432 13.9923 24.4137 13.3454C24.6628 11.1282 24.2867 8.88761 23.3325 6.86729Z"
                    fill="#AF272F"
                  />
                  <path
                    d="M16.338 7.93135L10.6688 13.5845L8.56748 11.4892C8.10679 11.0298 7.36406 11.0298 6.90337 11.4892C6.44269 11.9485 6.44269 12.6892 6.90337 13.1485L9.83671 16.0735C10.067 16.3032 10.3679 16.4157 10.6688 16.4157C10.9696 16.4157 11.2705 16.3032 11.5008 16.0735L17.9974 9.59072C18.4581 9.13135 18.4581 8.39072 17.9974 7.93135C17.5367 7.47666 16.794 7.47666 16.338 7.93135Z"
                    fill="#AF272F"
                  />
                </svg>
              ) : (
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.9162 6.86729C21.9337 4.79073 20.3777 3.04698 18.4175 1.82354C15.691 0.12667 12.4615 -0.412392 9.33072 0.309483C6.19995 1.02667 3.53456 2.92042 1.83285 5.63917C0.126437 8.35792 -0.414162 11.5735 0.309771 14.7001C1.0337 17.822 2.93285 20.4798 5.65465 22.1767C7.5726 23.372 9.7726 24.0048 12.0243 24.0048H12.17C14.4124 23.9767 16.5936 23.3298 18.4833 22.1392C19.0333 21.7923 19.1931 21.0704 18.8453 20.522C18.4974 19.9735 17.7735 19.8142 17.2235 20.161C15.7051 21.122 13.947 21.6423 12.1418 21.6657C10.2897 21.6892 8.47987 21.1782 6.90038 20.1985C4.70508 18.8298 3.1773 16.6923 2.59909 14.1798C2.02088 11.6673 2.45336 9.07979 3.82601 6.89073C6.65593 2.37667 12.6448 0.998545 17.1718 3.82042C18.7512 4.8048 20.0017 6.20636 20.7914 7.87511C21.5624 9.50167 21.8632 11.3064 21.6611 13.0876C21.5906 13.7298 22.0513 14.311 22.7 14.3814C23.344 14.4517 23.9269 13.9923 23.9974 13.3454C24.2466 11.1282 23.8705 8.88761 22.9162 6.86729Z"
                    fill="#AF272F"
                    fill-opacity="0.4"
                  />
                </svg>
              )}
              Freelance
            </label>
          </div>
          <div>
            <label className="salary-label" htmlFor="salary">
              Podane wynagrodzenie
              <div
                className={`checkbox-mark-background ${
                  salaryFilter === "constant" ? "active" : ""
                }`}
              >
                <div
                  className={`checkbox-mark ${
                    salaryFilter === "constant" ? "active" : ""
                  }`}
                ></div>
              </div>
            </label>
            <input
              type="checkbox"
              name="salary"
              id="salary"
              checked={salaryFilter === "constant"}
              onChange={() => {
                salaryFilter === "constant"
                  ? setSalaryFilter("any")
                  : setSalaryFilter("constant");
              }}
            />
          </div>
        </div>
      </div>
      <div className="offers-display">{generateOffers()}</div>
    </section>
  );
}

import { useEffect } from "react";

import PageTitleSection from "../components/PageTitleSection"

export default function TeamPage() {

    useEffect(() => {
        document.title = `CODERHINO - Zespół`;
    }, []);

    return (
        <>
            <PageTitleSection title="Zespół" />
            <div className="container">
                TEAM PAGE
            </div>
        </>
    )
}
import { useState, useEffect } from "react";

import axios from "axios";
import { Link } from "react-router-dom";

export default function PostsSection() {

    const [posts, setPosts] = useState([])

    function generatePosts() {
        let elements = [];

        for (const post of posts) {
            elements.push(
                <div className="post">
                    <p className="post-date">
                        { post.date.replace('T', ' ') }
                    </p>
                    <img src={post._embedded["wp:featuredmedia"][0].source_url} alt="" />
                    <p className="post-title">
                        { post.title.rendered }
                    </p>
                    <Link className="post-link" to={`p/${post.slug}`}>
                        Czytaj więcej...
                    </Link>
                </div>
            )
        }

        return elements;
    }

    useEffect(() => {
        axios.get(`https://wp.coderhino.cc//wp-json/wp/v2/posts?per_page=3&_embed`)
        .then(response => {
            setPosts(response.data)
        }) 
        .catch(error => console.error(error));
    }, [])
    

    return (
        <section id="posts">
            <div className="container posts-container">
                <p className="section-header">
                    Najnowsze posty
                </p>
                <div className="posts">
                    { generatePosts() }
                </div>
            </div>
        </section>
    )
}
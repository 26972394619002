import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { gsap } from "gsap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowRightLong,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";

import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ServicesChoice() {
  const { height, width } = useWindowDimensions();

  const [selectedService, setSelectedService] = useState("pages");

  function generateService() {
    if (selectedService === "pages")
      return (
        <div className="service-chosen-display">
          <img
            src={window.location.origin + "/img/websites-service.png"}
            alt=""
          />
          <div className="service-chosen-infos">
            <p className="service-chosen-title">Strony internetowe</p>
            <p className="service-chosen-text">
              Phosfluorescently engage worldwide methodologies with web-enabled
              technology. Interactively coordinate proactive e-commerce via
              process-centric "outside the box" thinking. Completely pursue
              scalable customer service through sustainable potentialities.
              Objectively innovate empowered manufactured products whereas
              parallel platforms.{" "}
            </p>
            <Link
              className="service-chosen-details-button"
              to="/co-robimy/strony-internetowe"
              aria-label="Zobacz szczegóły"
            >
              Zobacz szczegóły
              <span>
                <FontAwesomeIcon icon={faArrowRightLong} />
              </span>
            </Link>
          </div>
        </div>
      );
    if (selectedService === "shops")
      return (
        <div className="service-chosen-display">
          <img
            src={window.location.origin + "/img/ecommerce-service.png"}
            alt=""
          />
          <div className="service-chosen-infos">
            <p className="service-chosen-title">Sklepy internetowe</p>
            <p className="service-chosen-text">
              Phosfluorescently engage worldwide methodologies with web-enabled
              technology. Interactively coordinate proactive e-commerce via
              process-centric "outside the box" thinking. Completely pursue
              scalable customer service through sustainable potentialities.
              Objectively innovate empowered manufactured products whereas
              parallel platforms.{" "}
            </p>
            <Link
              className="service-chosen-details-button"
              to="/co-robimy/sklepy-internetowe"
              aria-label="Zobacz szczegóły"
            >
              Zobacz szczegóły
              <span>
                <FontAwesomeIcon icon={faArrowRightLong} />
              </span>
            </Link>
          </div>
        </div>
      );
    if (selectedService === "handling")
      return (
        <div className="service-chosen-display">
          <img
            src={window.location.origin + "/img/handling-service.png"}
            alt=""
          />
          <div className="service-chosen-infos">
            <p className="service-chosen-title">Obsługa stron internetowych</p>
            <p className="service-chosen-text">
              Phosfluorescently engage worldwide methodologies with web-enabled
              technology. Interactively coordinate proactive e-commerce via
              process-centric "outside the box" thinking. Completely pursue
              scalable customer service through sustainable potentialities.
              Objectively innovate empowered manufactured products whereas
              parallel platforms.{" "}
            </p>
            <Link
              className="service-chosen-details-button"
              to="/co-robimy/obsluga-stron-internetowych"
              aria-label="Zobacz szczegóły"
            >
              Zobacz szczegóły
              <span>
                <FontAwesomeIcon icon={faArrowRightLong} />
              </span>
            </Link>
          </div>
        </div>
      );
    if (selectedService === "functionalities")
      return (
        <div className="service-chosen-display">
          <img
            src={window.location.origin + "/img/functionalities-service.png"}
            alt=""
          />
          <div className="service-chosen-infos">
            <p className="service-chosen-title">Dedykowane funkcjonalności</p>
            <p className="service-chosen-text">
              Phosfluorescently engage worldwide methodologies with web-enabled
              technology. Interactively coordinate proactive e-commerce via
              process-centric "outside the box" thinking. Completely pursue
              scalable customer service through sustainable potentialities.
              Objectively innovate empowered manufactured products whereas
              parallel platforms.{" "}
            </p>
            <Link
              className="service-chosen-details-button"
              to="/co-robimy/funkcjonalności"
              aria-label="Zobacz szczegóły"
            >
              Zobacz szczegóły
              <span>
                <FontAwesomeIcon icon={faArrowRightLong} />
              </span>
            </Link>
          </div>
        </div>
      );
    if (selectedService === "optimalization")
      return (
        <div className="service-chosen-display">
          <img
            src={window.location.origin + "/img/optimalization-service.png"}
            alt=""
          />
          <div className="service-chosen-infos">
            <p className="service-chosen-title">Optymalizacja stron WWW</p>
            <p className="service-chosen-text">
              Phosfluorescently engage worldwide methodologies with web-enabled
              technology. Interactively coordinate proactive e-commerce via
              process-centric "outside the box" thinking. Completely pursue
              scalable customer service through sustainable potentialities.
              Objectively innovate empowered manufactured products whereas
              parallel platforms.{" "}
            </p>
            <Link
              className="service-chosen-details-button"
              to="/co-robimy/optymalizacja"
              aria-label="Zobacz szczegóły"
            >
              Zobacz szczegóły
              <span>
                <FontAwesomeIcon icon={faArrowRightLong} />
              </span>
            </Link>
          </div>
        </div>
      );
    if (selectedService === "body-leasing")
      return (
        <div className="service-chosen-display">
          <img
            src={window.location.origin + "/img/body-leasing-service.png"}
            alt=""
          />
          <div className="service-chosen-infos">
            <p className="service-chosen-title">Body-leasing Wordpress Devs</p>
            <p className="service-chosen-text">
              Phosfluorescently engage worldwide methodologies with web-enabled
              technology. Interactively coordinate proactive e-commerce via
              process-centric "outside the box" thinking. Completely pursue
              scalable customer service through sustainable potentialities.
              Objectively innovate empowered manufactured products whereas
              parallel platforms.{" "}
            </p>
            <Link
              className="service-chosen-details-button"
              to="/body-leasing"
              aria-label="Zobacz szczegóły"
            >
              Zobacz szczegóły
              <span>
                <FontAwesomeIcon icon={faArrowRightLong} />
              </span>
            </Link>
          </div>
        </div>
      );
  }

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.fromTo(
        document.getElementsByClassName("service-chosen-display"),
        { y: -100 },
        { y: 0 }
      );
    });
    return () => ctx.revert();
  }, [selectedService]);

  return (
    <section id="services" className="container services-choice-container">
      <p className="section-header">
        Świadczymy usługi Wordpressowe w modelu white-label
      </p>
      <div className="services-main">
        <div className="services-selects">
          <div
            className={`services-select ${
              selectedService === "pages" ? "active-services-select" : ""
            }`}
            onMouseEnter={() => {
              if (width > 992) setSelectedService("pages");
            }}
            onClick={() => {
              if (width <= 992) setSelectedService("pages");
            }}
          >
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="black"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56 15.75C56 16.7165 55.2165 17.5 54.25 17.5H3.5V50.75C3.5 51.7165 4.2835 52.5 5.25 52.5H50.75C51.7165 52.5 52.5 51.7165 52.5 50.75V22.75C52.5 21.7835 53.2835 21 54.25 21C55.2165 21 56 21.7835 56 22.75V50.75C56 53.6495 53.6495 56 50.75 56H5.25C2.3505 56 0 53.6495 0 50.75V5.25C0 2.3505 2.3505 0 5.25 0H50.75C53.6495 0 56 2.3505 56 5.25V15.75ZM5.25 3.5C4.2835 3.5 3.5 4.2835 3.5 5.25V14H52.5V5.25C52.5 4.2835 51.7165 3.5 50.75 3.5H5.25Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M47.25 7C46.2835 7 45.5 7.7835 45.5 8.75C45.5 9.7165 46.2835 10.5 47.25 10.5C48.2165 10.5 49 9.7165 49 8.75C49 7.7835 48.2165 7 47.25 7Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M40.25 7C39.2835 7 38.5 7.7835 38.5 8.75C38.5 9.7165 39.2835 10.5 40.25 10.5C41.2165 10.5 42 9.7165 42 8.75C42 7.7835 41.2165 7 40.25 7Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M33.25 7C32.2835 7 31.5 7.7835 31.5 8.75C31.5 9.7165 32.2835 10.5 33.25 10.5C34.2165 10.5 35 9.7165 35 8.75C35 7.7835 34.2165 7 33.25 7Z"
                fill="currentColor"
              />
              <path
                className="red"
                d="M19.2499 41.9999C18.7847 42.0026 18.3377 41.82 18.0074 41.4924L12.7574 36.2424C12.4261 35.9138 12.2397 35.4666 12.2397 34.9999C12.2397 34.5333 12.4261 34.086 12.7574 33.7574L18.0074 28.5074C18.4513 28.0635 19.0983 27.8902 19.7047 28.0527C20.3111 28.2151 20.7847 28.6888 20.9472 29.2952C21.1097 29.9015 20.9363 30.5485 20.4924 30.9924L16.4674 34.9999L20.4924 39.0074C20.9972 39.5081 21.1489 40.2644 20.8763 40.921C20.6037 41.5777 19.9609 42.0041 19.2499 41.9999Z"
                fill="currentColor"
              />
              <path
                className="red"
                d="M36.75 42C36.039 42.0042 35.3962 41.5777 35.1236 40.9211C34.8509 40.2645 35.0027 39.5082 35.5075 39.0075L39.5325 35L35.5075 30.9925C34.8213 30.3063 34.8213 29.1937 35.5075 28.5075C36.1937 27.8213 37.3063 27.8213 37.9925 28.5075L43.2425 33.7575C43.5738 34.0861 43.7601 34.5334 43.7601 35C43.7601 35.4666 43.5738 35.9139 43.2425 36.2425L37.9925 41.4925C37.6622 41.8201 37.2151 42.0027 36.75 42Z"
                fill="currentColor"
              />
              <path
                className="red"
                d="M26.2501 43.75C25.7031 43.7661 25.1801 43.5253 24.8365 43.0994C24.493 42.6734 24.3685 42.1112 24.5001 41.58L28.0001 27.58C28.1299 26.9519 28.5929 26.4452 29.2068 26.2593C29.8207 26.0735 30.487 26.2383 30.9434 26.689C31.3998 27.1396 31.5731 27.8039 31.3951 28.42L27.8951 42.42C27.7071 43.1821 27.0346 43.7258 26.2501 43.75Z"
                fill="currentColor"
              />
            </svg>

            <p className="services-select-text">Strony internetowe</p>
            <FontAwesomeIcon
              icon={faCaretDown}
              size="xs"
              className="active-services-select-arrow"
            />
          </div>
          <div
            className={`services-select ${
              selectedService === "shops" ? "active-services-select" : ""
            }`}
            onMouseEnter={() => {
              if (width > 992) setSelectedService("shops");
            }}
            onClick={() => {
              if (width <= 992) setSelectedService("shops");
            }}
          >
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="black"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56 15.75C56 16.7165 55.2165 17.5 54.25 17.5H3.5V50.75C3.5 51.7165 4.2835 52.5 5.25 52.5H50.75C51.7165 52.5 52.5 51.7165 52.5 50.75V22.75C52.5 21.7835 53.2835 21 54.25 21C55.2165 21 56 21.7835 56 22.75V50.75C56 53.6495 53.6495 56 50.75 56H5.25C2.3505 56 0 53.6495 0 50.75V5.25C0 2.3505 2.3505 0 5.25 0H50.75C53.6495 0 56 2.3505 56 5.25V15.75ZM5.25 3.5C4.2835 3.5 3.5 4.2835 3.5 5.25V14H52.5V5.25C52.5 4.2835 51.7165 3.5 50.75 3.5H5.25Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M47.25 7C46.2835 7 45.5 7.7835 45.5 8.75C45.5 9.7165 46.2835 10.5 47.25 10.5C48.2165 10.5 49 9.7165 49 8.75C49 7.7835 48.2165 7 47.25 7Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M40.25 7C39.2835 7 38.5 7.7835 38.5 8.75C38.5 9.7165 39.2835 10.5 40.25 10.5C41.2165 10.5 42 9.7165 42 8.75C42 7.7835 41.2165 7 40.25 7Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M33.25 7C32.2835 7 31.5 7.7835 31.5 8.75C31.5 9.7165 32.2835 10.5 33.25 10.5C34.2165 10.5 35 9.7165 35 8.75C35 7.7835 34.2165 7 33.25 7Z"
                fill="currentColor"
              />
              <path
                className="red"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14 21.8556C14 21.383 14.383 21 14.8556 21H17.4222C17.8148 21 18.157 21.2672 18.2522 21.6481L18.9458 24.4222H38.8111C39.0656 24.4222 39.3069 24.5355 39.4695 24.7314C39.632 24.9272 39.6989 25.1853 39.652 25.4354L37.0853 39.1243C37.0095 39.529 36.6561 39.8222 36.2444 39.8222H34.5333H22.5556H20.8444C20.4327 39.8222 20.0794 39.529 20.0035 39.1243L17.4416 25.4605L16.7542 22.7111H14.8556C14.383 22.7111 14 22.3281 14 21.8556ZM19.3087 26.1333L21.5545 38.1111H35.5344L37.7802 26.1333H19.3087ZM22.5556 39.8222C20.6655 39.8222 19.1333 41.3544 19.1333 43.2444C19.1333 45.1345 20.6655 46.6667 22.5556 46.6667C24.4456 46.6667 25.9778 45.1345 25.9778 43.2444C25.9778 41.3544 24.4456 39.8222 22.5556 39.8222ZM34.5333 39.8222C32.6433 39.8222 31.1111 41.3544 31.1111 43.2444C31.1111 45.1345 32.6433 46.6667 34.5333 46.6667C36.4234 46.6667 37.9555 45.1345 37.9555 43.2444C37.9555 41.3544 36.4234 39.8222 34.5333 39.8222ZM22.5556 41.5333C21.6105 41.5333 20.8444 42.2994 20.8444 43.2444C20.8444 44.1895 21.6105 44.9556 22.5556 44.9556C23.5006 44.9556 24.2667 44.1895 24.2667 43.2444C24.2667 42.2994 23.5006 41.5333 22.5556 41.5333ZM34.5333 41.5333C33.5883 41.5333 32.8222 42.2994 32.8222 43.2444C32.8222 44.1895 33.5883 44.9556 34.5333 44.9556C35.4783 44.9556 36.2444 44.1895 36.2444 43.2444C36.2444 42.2994 35.4783 41.5333 34.5333 41.5333Z"
                fill="currentColor"
              />
              <path
                className="red"
                d="M34.5333 39.8222H36.2444C36.6561 39.8222 37.0095 39.529 37.0853 39.1243L39.652 25.4354C39.6989 25.1853 39.632 24.9272 39.4695 24.7314C39.3069 24.5355 39.0656 24.4222 38.8111 24.4222H18.9458L18.2522 21.6481C18.157 21.2672 17.8148 21 17.4222 21H14.8556C14.383 21 14 21.383 14 21.8556C14 22.3281 14.383 22.7111 14.8556 22.7111H16.7542L17.4416 25.4605L20.0035 39.1243C20.0794 39.529 20.4327 39.8222 20.8444 39.8222H22.5556M34.5333 39.8222H22.5556M34.5333 39.8222C32.6433 39.8222 31.1111 41.3544 31.1111 43.2444C31.1111 45.1345 32.6433 46.6667 34.5333 46.6667C36.4234 46.6667 37.9555 45.1345 37.9555 43.2444C37.9555 41.3544 36.4234 39.8222 34.5333 39.8222ZM22.5556 39.8222C20.6655 39.8222 19.1333 41.3544 19.1333 43.2444C19.1333 45.1345 20.6655 46.6667 22.5556 46.6667C24.4456 46.6667 25.9778 45.1345 25.9778 43.2444C25.9778 41.3544 24.4456 39.8222 22.5556 39.8222ZM21.5545 38.1111L19.3087 26.1333H37.7802L35.5344 38.1111H21.5545ZM20.8444 43.2444C20.8444 42.2994 21.6105 41.5333 22.5556 41.5333C23.5006 41.5333 24.2667 42.2994 24.2667 43.2444C24.2667 44.1895 23.5006 44.9556 22.5556 44.9556C21.6105 44.9556 20.8444 44.1895 20.8444 43.2444ZM32.8222 43.2444C32.8222 42.2994 33.5883 41.5333 34.5333 41.5333C35.4783 41.5333 36.2444 42.2994 36.2444 43.2444C36.2444 44.1895 35.4783 44.9556 34.5333 44.9556C33.5883 44.9556 32.8222 44.1895 32.8222 43.2444Z"
                stroke="currentColor"
                stroke-width="0.8"
                stroke-linejoin="round"
              />
              <circle
                className="red"
                cx="22.5554"
                cy="42.3889"
                r="2.13889"
                fill="currentColor"
              />
              <circle
                className="red"
                cx="35"
                cy="43"
                r="3"
                fill="currentColor"
              />
            </svg>
            <p className="services-select-text">Sklepy internetowe</p>
            <FontAwesomeIcon
              icon={faCaretDown}
              size="xs"
              className="active-services-select-arrow"
            />
          </div>
          <div
            className={`services-select ${
              selectedService === "handling" ? "active-services-select" : ""
            }`}
            onMouseEnter={() => {
              if (width > 992) setSelectedService("handling");
            }}
            onClick={() => {
              if (width <= 992) setSelectedService("handling");
            }}
          >
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="red"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.7493 56.0001H33.2493C34.2158 56.0001 34.9993 55.2166 34.9993 54.2501V45.3776C39.4976 42.9036 42.2078 38.0967 41.9964 32.9674C41.785 27.8381 38.6884 23.2704 34.0018 21.1751C33.4604 20.9172 32.8247 20.9545 32.3172 21.2739C31.8097 21.5933 31.501 22.1504 31.4993 22.7501V32.3051L27.9993 34.6501L24.4993 32.3051V22.7501C24.4976 22.1504 24.189 21.5933 23.6815 21.2739C23.174 20.9545 22.5382 20.9172 21.9968 21.1751C17.3103 23.2704 14.2137 27.8381 14.0022 32.9674C13.7908 38.0967 16.5011 42.9036 20.9993 45.3776V54.2501C20.9993 55.2166 21.7828 56.0001 22.7493 56.0001ZM31.5 52.5001H24.5V44.3101C24.4981 43.6369 24.1102 43.0245 23.5025 42.7351C16.8175 39.5676 15.3125 30.5201 21 25.9001V33.2501C21.003 33.8355 21.2985 34.3806 21.7875 34.7026L27.0375 38.2026C27.6215 38.5872 28.3784 38.5872 28.9625 38.2026L34.2125 34.7026C34.7014 34.3806 34.9969 33.8355 35 33.2501V25.9001C37.6093 28.1575 38.8827 31.5926 38.3754 35.0053C37.8681 38.4181 35.6506 41.3343 32.4975 42.7351C31.8897 43.0245 31.5019 43.6369 31.5 44.3101V52.5001Z"
                fill="currentColor"
              />
              <path
                className="black"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M56 15.75C56 16.7165 55.2165 17.5 54.25 17.5H3.5V50.75C3.5 51.7165 4.2835 52.5 5.25 52.5H50.75C51.7165 52.5 52.5 51.7165 52.5 50.75V22.75C52.5 21.7835 53.2835 21 54.25 21C55.2165 21 56 21.7835 56 22.75V50.75C56 53.6495 53.6495 56 50.75 56H5.25C2.3505 56 0 53.6495 0 50.75V5.25C0 2.3505 2.3505 0 5.25 0H50.75C53.6495 0 56 2.3505 56 5.25V15.75ZM5.25 3.5C4.2835 3.5 3.5 4.2835 3.5 5.25V14H52.5V5.25C52.5 4.2835 51.7165 3.5 50.75 3.5H5.25Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M47.25 7C46.2835 7 45.5 7.7835 45.5 8.75C45.5 9.7165 46.2835 10.5 47.25 10.5C48.2165 10.5 49 9.7165 49 8.75C49 7.7835 48.2165 7 47.25 7Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M40.25 7C39.2835 7 38.5 7.7835 38.5 8.75C38.5 9.7165 39.2835 10.5 40.25 10.5C41.2165 10.5 42 9.7165 42 8.75C42 7.7835 41.2165 7 40.25 7Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M33.25 7C32.2835 7 31.5 7.7835 31.5 8.75C31.5 9.7165 32.2835 10.5 33.25 10.5C34.2165 10.5 35 9.7165 35 8.75C35 7.7835 34.2165 7 33.25 7Z"
                fill="currentColor"
              />
            </svg>
            <p className="services-select-text">Obsługa stron internetowych</p>
            <FontAwesomeIcon
              icon={faCaretDown}
              size="xs"
              className="active-services-select-arrow"
            />
          </div>
          <div
            className={`services-select ${
              selectedService === "functionalities"
                ? "active-services-select"
                : ""
            }`}
            onMouseEnter={() => {
              if (width > 992) setSelectedService("functionalities");
            }}
            onClick={() => {
              if (width <= 992) setSelectedService("functionalities");
            }}
          >
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M54.25 17.5C55.2165 17.5 56 16.7165 56 15.75V5.25C56 2.3505 53.6495 0 50.75 0H5.25C2.3505 0 0 2.3505 0 5.25V50.75C0 53.6495 2.3505 56 5.25 56H50.75C53.6495 56 56 53.6495 56 50.75V22.75C56 21.7835 55.2165 21 54.25 21C53.2835 21 52.5 21.7835 52.5 22.75V50.75C52.5 51.7165 51.7165 52.5 50.75 52.5H21V17.5H54.25ZM3.5 5.25C3.5 4.2835 4.2835 3.5 5.25 3.5H50.75C51.7165 3.5 52.5 4.2835 52.5 5.25V14H3.5V5.25ZM5.25 52.5H17.5V17.5H3.5V50.75C3.5 51.7165 4.2835 52.5 5.25 52.5Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M47.25 10.5C48.2165 10.5 49 9.7165 49 8.75C49 7.7835 48.2165 7 47.25 7C46.2835 7 45.5 7.7835 45.5 8.75C45.5 9.7165 46.2835 10.5 47.25 10.5Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M40.25 10.5C41.2165 10.5 42 9.7165 42 8.75C42 7.7835 41.2165 7 40.25 7C39.2835 7 38.5 7.7835 38.5 8.75C38.5 9.7165 39.2835 10.5 40.25 10.5Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M33.25 10.5C34.2165 10.5 35 9.7165 35 8.75C35 7.7835 34.2165 7 33.25 7C32.2835 7 31.5 7.7835 31.5 8.75C31.5 9.7165 32.2835 10.5 33.25 10.5Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M12.25 26.25H8.75C7.7835 26.25 7 25.4665 7 24.5C7 23.5335 7.7835 22.75 8.75 22.75H12.25C13.2165 22.75 14 23.5335 14 24.5C14 25.4665 13.2165 26.25 12.25 26.25Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M12.25 33.25H8.75C7.7835 33.25 7 32.4665 7 31.5C7 30.5335 7.7835 29.75 8.75 29.75H12.25C13.2165 29.75 14 30.5335 14 31.5C14 32.4665 13.2165 33.25 12.25 33.25Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M12.25 40.25H8.75C7.7835 40.25 7 39.4665 7 38.5C7 37.5335 7.7835 36.75 8.75 36.75H12.25C13.2165 36.75 14 37.5335 14 38.5C14 39.4665 13.2165 40.25 12.25 40.25Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M12.25 47.25H8.75C7.7835 47.25 7 46.4665 7 45.5C7 44.5335 7.7835 43.75 8.75 43.75H12.25C13.2165 43.75 14 44.5335 14 45.5C14 46.4665 13.2165 47.25 12.25 47.25Z"
                fill="currentColor"
              />
              <path
                className="red"
                d="M47.25 26.25H26.25C25.2835 26.25 24.5 25.4665 24.5 24.5C24.5 23.5335 25.2835 22.75 26.25 22.75H47.25C48.2165 22.75 49 23.5335 49 24.5C49 25.4665 48.2165 26.25 47.25 26.25Z"
                fill="currentColor"
              />
              <path
                className="red"
                d="M47.25 40.25H29.75C28.7835 40.25 28 39.4665 28 38.5C28 37.5335 28.7835 36.75 29.75 36.75H47.25C48.2165 36.75 49 37.5335 49 38.5C49 39.4665 48.2165 40.25 47.25 40.25Z"
                fill="currentColor"
              />
              <path
                className="red"
                d="M47.25 33.25H33.25C32.2835 33.25 31.5 32.4665 31.5 31.5C31.5 30.5335 32.2835 29.75 33.25 29.75H47.25C48.2165 29.75 49 30.5335 49 31.5C49 32.4665 48.2165 33.25 47.25 33.25Z"
                fill="currentColor"
              />
              <path
                className="red"
                d="M47.25 47.25H33.25C32.2835 47.25 31.5 46.4665 31.5 45.5C31.5 44.5335 32.2835 43.75 33.25 43.75H47.25C48.2165 43.75 49 44.5335 49 45.5C49 46.4665 48.2165 47.25 47.25 47.25Z"
                fill="currentColor"
              />
            </svg>
            <p className="services-select-text">Dedykowane funkcjonalności</p>
            <FontAwesomeIcon
              icon={faCaretDown}
              size="xs"
              className="active-services-select-arrow"
            />
          </div>
          <div
            className={`services-select ${
              selectedService === "optimalization"
                ? "active-services-select"
                : ""
            }`}
            onMouseEnter={() => {
              if (width > 992) setSelectedService("optimalization");
            }}
            onClick={() => {
              if (width <= 992) setSelectedService("optimalization");
            }}
          >
            <svg
              width="50"
              height="56"
              viewBox="0 0 50 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="black"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.75 0H44.25C47.1495 0 49.5 2.3505 49.5 5.25V12.25C49.5 13.2165 48.7165 14 47.75 14H4V40.25C4 41.2165 4.7835 42 5.75 42H9.25C10.2165 42 11 42.7835 11 43.75C11 44.7165 10.2165 45.5 9.25 45.5H5.75C2.8505 45.5 0.5 43.1495 0.5 40.25V5.25C0.5 2.3505 2.8505 0 5.75 0ZM4 5.25V10.5H46V5.25C46 4.2835 45.2165 3.5 44.25 3.5H5.75C4.7835 3.5 4 4.2835 4 5.25Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M47.75 17.5C46.7835 17.5 46 18.2835 46 19.25V40.25C46 41.2165 45.2165 42 44.25 42H40.75C39.7835 42 39 42.7835 39 43.75C39 44.7165 39.7835 45.5 40.75 45.5H44.25C47.1495 45.5 49.5 43.1495 49.5 40.25V19.25C49.5 18.2835 48.7165 17.5 47.75 17.5Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M12.75 17.5H9.25C8.2835 17.5 7.5 18.2835 7.5 19.25C7.5 20.2165 8.2835 21 9.25 21H12.75C13.7165 21 14.5 20.2165 14.5 19.25C14.5 18.2835 13.7165 17.5 12.75 17.5Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M12.75 24.5H9.25C8.2835 24.5 7.5 25.2835 7.5 26.25C7.5 27.2165 8.2835 28 9.25 28H12.75C13.7165 28 14.5 27.2165 14.5 26.25C14.5 25.2835 13.7165 24.5 12.75 24.5Z"
                fill="currentColor"
              />
              <path
                className="black"
                d="M42.5 19.25C42.5 18.2835 41.7165 17.5 40.75 17.5H37.25C36.2835 17.5 35.5 18.2835 35.5 19.25C35.5 20.2165 36.2835 21 37.25 21H40.75C41.7165 21 42.5 20.2165 42.5 19.25Z"
                fill="currentColor"
              />
              <path
                className="red"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25 28C15.335 28 7.5 35.835 7.5 45.5V50.75C7.5 53.6495 9.8505 56 12.75 56H37.25C40.1495 56 42.5 53.6495 42.5 50.75V45.5C42.5 35.835 34.665 28 25 28ZM21.5 52.5C21.5 50.567 23.067 49 25 49C26.933 49 28.5 50.567 28.5 52.5H21.5ZM37.25 52.5C38.2165 52.5 39 51.7165 39 50.75V45.5C39 37.768 32.732 31.5 25 31.5C17.268 31.5 11 37.768 11 45.5V50.75C11 51.7165 11.7835 52.5 12.75 52.5H18C18.0103 49.3165 20.1675 46.5409 23.25 45.745V36.75C23.25 35.7835 24.0335 35 25 35C25.9665 35 26.75 35.7835 26.75 36.75V45.745C29.8325 46.5409 31.9897 49.3165 32 52.5H37.25Z"
                fill="currentColor"
              />
            </svg>
            <p className="services-select-text">Optymalizacja stron WWW</p>
            <FontAwesomeIcon
              icon={faCaretDown}
              size="xs"
              className="active-services-select-arrow"
            />
          </div>
          <div
            className={`services-select ${
              selectedService === "body-leasing" ? "active-services-select" : ""
            }`}
            onMouseEnter={() => {
              if (width > 992) setSelectedService("body-leasing");
            }}
            onClick={() => {
              if (width <= 992) setSelectedService("body-leasing");
            }}
          >
            <svg
              width="52"
              height="57"
              viewBox="0 0 52 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="black"
                d="M51.3103 32.618L46.2281 22.4009C45.9848 10.3188 36.3801 0.512982 24.3056 0.0193848C12.2311 -0.474212 1.85811 8.51493 0.62946 20.5369C0.062386 25.8043 1.36995 31.1031 4.32237 35.502C6.4194 38.6671 7.5386 42.3795 7.54048 46.1763V54.5117C7.54048 55.4829 8.3278 56.2702 9.29901 56.2702C10.2702 56.2702 11.0575 55.4829 11.0575 54.5117V46.1763C11.0618 41.6883 9.7337 37.3 7.24153 33.5676C1.79617 25.4626 3.24504 14.5701 10.6198 8.1703C17.9945 1.77054 28.9825 1.87036 36.2397 8.40305C43.3617 14.7513 42.4473 22.6296 42.8869 23.5967C43.1155 24.0891 41.6384 21.1348 47.5119 32.8818L41.656 37.2781C41.2131 37.6102 40.9525 38.1314 40.9525 38.6849V43.9605C40.9525 44.9317 40.1652 45.719 39.194 45.719H28.6428C27.6716 45.719 26.8843 46.5064 26.8843 47.4776C26.8843 48.4488 27.6716 49.2361 28.6428 49.2361H30.4014V54.5117C30.4014 55.4829 31.1887 56.2702 32.1599 56.2702C33.1311 56.2702 33.9184 55.4829 33.9184 54.5117V49.2361H39.194C42.1076 49.2361 44.4696 46.8741 44.4696 43.9605V39.5642L50.8003 34.8162C51.48 34.3035 51.6948 33.3776 51.3103 32.618Z"
                fill="currentColor"
              />
              <path
                className="red"
                d="M14.5751 29.8923C14.1077 29.895 13.6584 29.7115 13.3265 29.3824L8.05093 24.1068C7.71802 23.7766 7.53076 23.3271 7.53076 22.8582C7.53076 22.3893 7.71802 21.9398 8.05093 21.6097L13.3265 16.3341C14.0161 15.6445 15.1341 15.6445 15.8236 16.3341C16.5132 17.0236 16.5132 18.1416 15.8236 18.8312L11.779 22.8582L15.8236 26.8852C16.3309 27.3883 16.4834 28.1483 16.2094 28.8082C15.9354 29.468 15.2895 29.8965 14.5751 29.8923Z"
                fill="currentColor"
              />
              <path
                className="red"
                d="M32.1604 29.8922C31.446 29.8964 30.8 29.4679 30.5261 28.8081C30.2521 28.1483 30.4046 27.3883 30.9119 26.8852L34.9565 22.8581L30.9119 18.8311C30.4658 18.385 30.2916 17.7349 30.4549 17.1255C30.6181 16.5162 31.0941 16.0402 31.7034 15.877C32.3127 15.7137 32.9629 15.8879 33.409 16.334L38.6846 21.6096C39.0175 21.9398 39.2047 22.3892 39.2047 22.8581C39.2047 23.327 39.0175 23.7765 38.6846 24.1067L33.409 29.3823C33.0771 29.7114 32.6278 29.8949 32.1604 29.8922Z"
                fill="currentColor"
              />
              <path
                className="red"
                d="M21.6081 33.4093C21.0739 33.4201 20.5637 33.1874 20.2216 32.7769C19.8795 32.3664 19.7426 31.8226 19.8496 31.2991L23.3666 13.7138C23.4923 13.0855 23.9436 12.572 24.5505 12.3667C25.1574 12.1614 25.8278 12.2955 26.309 12.7184C26.7903 13.1414 27.0094 13.7889 26.8837 14.4172L23.3666 32.0025C23.197 32.8345 22.4571 33.4264 21.6081 33.4093Z"
                fill="currentColor"
              />
            </svg>
            <p className="services-select-text">Body-leasing Wordpress Devs</p>
            <FontAwesomeIcon
              icon={faCaretDown}
              size="xs"
              className="active-services-select-arrow"
            />
          </div>
        </div>
        {generateService()}
      </div>
    </section>
  );
}

import { useEffect } from "react";

import { gsap } from "gsap";

import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function HowItsWorkingSection() {

  const { width, height } = useWindowDimensions();

  useEffect(() => {
    let ctx = gsap.context(() => {
      if (width <= 992) {
        gsap.timeline({ scrollTrigger: {
          trigger: document.getElementById("how-its-working"), 
          start: "top bottom",
          scrub: false, 
          once: true,
        }})
        .fromTo(".htw-step-1", { x: -50, opacity: .5 }, { x: 0, opacity: 1, duration: .3 })
        .fromTo(".htw-step-2", { x: 50, opacity: .5 }, { x: 0, opacity: 1, duration: .3  })
        .fromTo(".htw-step-3", { x: -50, opacity: .5 }, { x: 0, opacity: 1, duration: .3  })
        .fromTo(".htw-step-4", { x: 50, opacity: .5 }, { x: 0, opacity: 1, duration: .3  })
        .fromTo(".htw-step-5", { x: -50, opacity: .5 }, { x: 0, opacity: 1, duration: .3  })
        .fromTo(".htw-step-6", { x: 50, opacity: .5 }, { x: 0, opacity: 1, duration: .3  })
        .fromTo(".htw-step-7", { x: -50, opacity: .5 }, { x: 0, opacity: 1, duration: .3  })
        .fromTo(".htw-step-8", { x: 50, opacity: .5 }, { x: 0, opacity: 1, duration: .3  })
      } else {
        gsap.timeline({ scrollTrigger: {
          trigger: document.getElementById("how-its-working"), 
          start: "top bottom",
          scrub: false, 
          once: true,
        }})
        .fromTo(".htw-step-1", { y: -50, opacity: .5 }, { y: 0, opacity: 1, duration: .2 }, 0)
        .fromTo(".htw-step-5", { y: 50, opacity: .5 }, { y: 0, opacity: 1, duration: .2  }, 0)
        .fromTo(".htw-step-4", { y: -50, opacity: .5 }, { y: 0, opacity: 1, duration: .2  }, 0)
        .fromTo(".htw-step-8", { y: 50, opacity: .5 }, { y: 0, opacity: 1, duration: .2  }, 0)
        .fromTo(".htw-step-2", { y: -50, opacity: .5 }, { y: 0, opacity: 1, duration: .2  }, 1)
        .fromTo(".htw-step-7", { y: 50, opacity: .5 }, { y: 0, opacity: 1, duration: .2  }, 1)
        .fromTo(".htw-step-3", { y: -50, opacity: .5 }, { y: 0, opacity: 1, duration: .2  }, 1)
        .fromTo(".htw-step-6", { y: 50, opacity: .5 }, { y: 0, opacity: 1, duration: .2  }, 1)
        
      }
  });
  return () => ctx.revert()
    
  }, [])

  return (
    <section id="how-its-working" className="htw-container">
      <div className="container">
        <p className="htw-header">Jak to działa?</p>
        <div className="htw-steps-display">
          <div className="htw-step-display htw-step-1">
            <div className="htw-step-line"></div>
            <p className="htw-step-number">01</p>
            <p className="htw-step-title">Nawiązujesz kontakt z klientem</p>
            <p className="htw-step-text">
              Nawiąż kontakt z klientem dowolną drogą. Możesz spotkać się z nim
              osobiście. Zbierz wstępne wymagania od klienta. Możemy udostępnić
              Ci <span>nieobrandowany formularz.</span>
            </p>
          </div>
          <div className="htw-step-display htw-step-2">
            <div className="htw-step-line"></div>
            <p className="htw-step-number">02</p>
            <p className="htw-step-title">Wstępna wycena projektu</p>
            <p className="htw-step-text">
              Na podstawie zebranych informacji dokonujesz wstępnej wyceny w oparciu o dostarczony kalkulator.&nbsp;
              <span>Do uzyskanej wyceny dolicz swoją marżę.</span>
            </p>
          </div>
          <div className="htw-step-display htw-step-3">
            <div className="htw-step-line"></div>
            <p className="htw-step-number">03</p>
            <p className="htw-step-title">Umowa przedwstępna</p>
            <p className="htw-step-text">
              Aby zabezpieczyć warunki wszystkich stron, proponujemy podpisanie
              umowy przedwstępnej,&nbsp;
              <span>uzależnionej od powodzenia Twojej umowy z klientem. </span>
            </p>
          </div>
          <div className="htw-step-display htw-step-4">
            <div className="htw-step-line"></div>
            <p className="htw-step-number">04</p>
            <p className="htw-step-title">Umowa z klientem</p>
            <p className="htw-step-text">
              Ustalasz wszystkie szczegóły pomiędzy sobą a klientem, a następnie
              podpisujesz umowę z klientem. Po tym pamiętaj o&nbsp;
              <span>sfinalizowaniu podpisania umowy z nami.</span>
            </p>
          </div>
          <div className="htw-step-display htw-step-5">
            <div className="htw-step-line"></div>
            <p className="htw-step-number">05</p>
            <p className="htw-step-title">Zapłata zaliczki</p>
            <p className="htw-step-text">
              Z racji na konieczność zabezpieczenia swoich dóbr, każdorazowo pobieramy od Ciebie zaliczkę na poczet realizacji. <span>Rekomendujemy, abyś robił to samo. Ale decyzje biznesowe pozostawiamy Tobie.</span>
            </p>
          </div>
          <div className="htw-step-display htw-step-6">
            <div className="htw-step-line"></div>
            <p className="htw-step-number">06</p>
            <p className="htw-step-title">Projekt graficzny</p>
            <p className="htw-step-text">
              Naszą realizację zaczynamy od utworzenia projektu graficznego lub wybrania gotowego motywu premium. Na tym etapie wymagany jest <span>kontakt z klientem</span> w celu akceptacji prac.
            </p>
          </div>
          <div className="htw-step-display htw-step-7">
            <div className="htw-step-line"></div>
            <p className="htw-step-number">07</p>
            <p className="htw-step-title">Prace programistyczne</p>
            <p className="htw-step-text">
              Po serii rozmów z klientem i ustaleniu końcowych warunków wizualnych strony internetowej, przechodzimy do realizacji prac programistycznych <span>to one stanowią lwią część realizacji</span>.
            </p>
          </div>
          <div className="htw-step-display htw-step-8">
            <div className="htw-step-line"></div>
            <p className="htw-step-number">08</p>
            <p className="htw-step-title">Oddanie prac</p>
            <p className="htw-step-text">
              W trakcie poprzednich kroków wielokrotnie kontaktujemy się z Tobą w celu potwierdzenia prac. Oddanie prac następuje po <span>podpisaniu protokołu zdawczo-odbiorczego</span>. Wtedy też dokonujemy wdrożenia.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

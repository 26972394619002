import { useEffect } from "react";
import { Link } from "react-router-dom";

import { gsap } from "gsap";

import HeaderSwiper from "./HeaderSwiper";
import ServiceSwiper from "./ServiceSwiper";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faLaptopCode,
  faPenRuler,
  faGear,
  faChevronRight,
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";

export default function HeaderSection(props) {

  const { height, width } = useWindowDimensions();
  
  function generateServices(display) {
    const services = [
      <div className="service-red" key={1}>
        <FontAwesomeIcon icon={faPenRuler} size="2xl" />
        <p className="service-header">Od grafiki do strony internetowej</p>
        <p className="service-text">
          Masz projekt graficzny? Zrobimy z niego działającą stronę internetową.
        </p>
        <Link to="/co-robimy/strony-internetowe" className="service-button">
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>,
      <div className="service-blue" key={2}>
        <FontAwesomeIcon icon={faGear} size="2xl" />
        <p className="service-header">Obsługa stron </p>
        <p className="service-text">Regularna obsługa stron internetowych. </p>
        <Link to="/co-robimy/obsluga-stron-internetowych" className="service-button">
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>,
      <div className="service-green" key={3}>
        <FontAwesomeIcon icon={faLaptopCode} size="2xl" />
        <p className="service-header">Dedykowane funkcjonalności </p>
        <p className="service-text">
          Niestraszne nam realizacje dedykowanych wtyczek i motywów.
          Skomplikowane formularze? Komunikacja z API? Świetnie trafiłeś!
        </p>
        <Link to="/co-robimy/funkcjonalności" className="service-button">
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>,
    ];

    if (display === "default") return services;
    if (display === "swiper") {
      return <ServiceSwiper services={services} />
    }
  }

  useEffect(() => {
    let ctx = gsap.context(() => {

      gsap.timeline()
        .fromTo(".header-left-text", { x: -400 }, { x: 0, duration: 1 })
        .fromTo(".services-container", { y: 100, opacity: 0 }, { y: 0, duration: 1, opacity: 1});
    });

    return () => ctx.revert()
  }, [])
  

  return (
    <section id="header" className="header-container" style={{ height: width > 1200 && height < 1100 && height > 800 ? `${height - 71}px` : "auto"}}>
      <div className="header-left">
        <div className="header-left-text">
          <p className="header-subtitle">
            WHITE-LABEL WORDPRESS DEVELOPMENT AGENCY
          </p>
          <p className="header-title">
            <span>
            Podwykonawcy od Wordpressa. <br />
            </span>
            Ty sprzedajesz. <br className="mobile-break" /> My robimy.
          </p>
          <a onClick={() => props.aboutRef.current.scrollIntoView({ behavior: "smooth" })} className="header-red-button" aria-label="Dowiedz się więcej">
            Dowiedz się więcej
            <FontAwesomeIcon icon={faArrowRightLong} />
          </a>
        </div>
        <div className="services-container">
            { width > 992 ? (
              generateServices("default") 
            ) : (
              generateServices("swiper") 
            )}
        </div>
      </div>
      <div className="header-right">
        { width > 1200 ? (
          <HeaderSwiper />
        ) : (
          <img src={window.location.origin + "/img/header-photo.avif"} alt="" />
        )}
      </div>
    </section>
  );
}

import { useEffect } from "react";

import PageTitleSection from "../components/PageTitleSection"

export default function ConsultationPage() {

    useEffect(() => {
        document.title = `CODERHINO - Konsultacja projektu`;
    }, []);

    return (
        <>
            <PageTitleSection title="Konsultacja projektu" />
            <div className="container">
                COSULTATION PAGE
            </div>
        </>
    )
}
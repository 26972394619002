import { useEffect } from "react";

import PageTitleSection from "../components/PageTitleSection"

export default function AboutUsPage() {

    useEffect(() => {
        document.title = `CODERHINO - O Nas`;
    }, []);

    return (
        <>
            <PageTitleSection title="O Nas" />
            <div className="container">
                ABOUT US PAGE
            </div>
        </>
    )
}
import { useEffect, useRef } from "react";

import SerivcePageTitleSection from "../../components/about-subpages/ServicePageTitle"
import ServicePageContent from "../../components/about-subpages/ServicePageContent"

export default function OptimalizationPage() {
     const pageData = {
        title: "Optymalizacja stron",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
        Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
        Praesent sollicitudin sit amet nisi eu commodo. 
        Vivamus lectus justo, dignissim.`,
        faq: [
            {
                question: "Question 1",
                answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
                Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
                Praesent sollicitudin sit amet nisi eu commodo. 
                Vivamus lectus justo, dignissim.`,
            },
            {
                question: "Question 2",
                answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
                Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
                Praesent sollicitudin sit amet nisi eu commodo. 
                Vivamus lectus justo, dignissim.`,
            },
            {
                question: "Question 3",
                answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
                Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
                Praesent sollicitudin sit amet nisi eu commodo. 
                Vivamus lectus justo, dignissim.`,
            },
        ]
    }

    const headerRef = useRef(null);

    useEffect(() => {
        document.title = `CODERHINO - ${pageData.title}`;
    }, []); 

    return (
        <>
            <SerivcePageTitleSection title={pageData.title} headerRef={headerRef} />
            <ServicePageContent pageData={pageData} headerRef={headerRef} />
        </>
    )
}
export default function CustomInput(props) {
    return (
        <div className={`form-element ${props.invalid ? "isInvalid" : ""}`}>
            <label htmlFor={props.id} className="form-element-label">
                <span className="form-element-title">{props.title} </span>
                <input type={props.type} id={props.id} name={props.id} placeholder={props.placeholder} onChange={props.onChange} className="form-element-input" />
            </label>
            {
                props.invalid ? (
                    <div class="form-element-invalid">
                        {props.invalid}
                    </div>
                ) : (null)
            }
        </div>
    )
}
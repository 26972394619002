import { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

export default function FAQQuestionDisplay(props) {

    const [showAnswer, setShowAnswer] = useState(false);

    return (
        <div>
            <button onClick={() => setShowAnswer(!showAnswer)}
            className="faq-question-button">
                { props.questionData.question }
                { showAnswer ? (
                    <FontAwesomeIcon icon={faMinus} />
                ) : (
                    <FontAwesomeIcon icon={faPlus} />
                )}
            </button>
            { showAnswer ? (
                <div>
                    <p>
                        { props.questionData.answer }
                    </p>
                </div>
            ) : (null)}
        </div>
    )
}
import { useRef, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import ContactForm from "../ContactForm";
import FAQQuestionDisplay from "./FAQQuestionDisplay";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faMap, faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { faEnvelope, faClock } from "@fortawesome/free-regular-svg-icons";

import useOutsideClick from "../../hooks/useOutsideClick";
import useScrollPosition from "../../hooks/useScrollPosition";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import RealizationDisplay from "../main-page/RealizationDisplay";

export default function ServicePageContent(props) {
  const [showPagesNavigation, setshowPagesNavigation] = useState(false);

  const ref = useOutsideClick(handleClickOutside);

  const scrollPostiion = useScrollPosition();
  const { height, width } = useWindowDimensions();

  function handleClickOutside() {
    if (setshowPagesNavigation) setshowPagesNavigation(false);
  }

  function generateSectionNavigation() {
    let elements = [];

    if ("description" in props.pageData) {
      elements.push(<a href="#o-usludze">O usłudze</a>);
    }
    if ("projects" in props.pageData) {
      elements.push(<a href="#projekty">Projekty</a>);
    }
    if ("faq" in props.pageData) {
      elements.push(<a href="#faq">FAQ</a>);
    }

    if ("customSections" in props.pageData) {
      for (const section of props.pageData.customSections) {
        elements.splice(section.order - 1, 0, <a href={`#${section.section.props.id}`}>{section.name}</a>)
      }
    }

    return elements;
  }

  function generateSections() {
    function generateFAQ() {
      let elements = [];

      for (const question of props.pageData.faq) {
        elements.push(<FAQQuestionDisplay questionData={question} />);
      }

      return elements;
    }

    function generateProjects() {
      let elements = [];

      for (const project of props.pageData.projects) {
        elements.push(<RealizationDisplay realization={project} />);
      }

      return elements;
    }

    let elements = [];

    if ("description" in props.pageData) {
      elements.push(
        <section id="o-usludze">
          <div className="container about-service-container">
            <p className="section-header">
              { props.pageData.header }
            </p>
            <p className="about-service-text">{props.pageData.description}</p>
          </div>
        </section>
      );
    }

    if ("projects" in props.pageData) {
      elements.push(<section id="projekty">
        <div className="container projects-container">
          <div>
            <p className="section-header">
              Projekty
            </p>
          </div>
          <div className="projects-display-container">
            { generateProjects() }
          </div>
        </div>
      </section>);
    }

    if ("faq" in props.pageData) {
      elements.push(
        <section id="faq">
          <div className="container faq-container">
            <div>
              <p className="section-header">
                FAQ
              </p>
            </div>
            <div className="faq-questions-container">
              {generateFAQ()}
            </div>
          </div>
        </section>
      );
    }

    if ("customSections" in props.pageData) {
      for (const section of props.pageData.customSections) {
        elements.splice(section.order - 1, 0, section.section)
      }
    }

    return elements;
  }

  return (
    <main>
      <div className={`service-navigation-container 
      ${props.headerRef.current ? (scrollPostiion > props.headerRef.current.getBoundingClientRect().height - (width > 992 ? 71 : 55) ? "service-navigation-container-fixed" : ""): ""}`} 
      style={{ top: width > 992 ? "71px" : "55px"}}>
        <div className="container service-navigation">
          <div className="pages-navigation" ref={ref}>
            <button className="show-navigation-button" onClick={() => setshowPagesNavigation(true)}>
              <span>
                {props.pageData.title}
              </span>
              <FontAwesomeIcon icon={faChevronDown} />
            </button>
            {showPagesNavigation ? (
              <div className="pages-select">
                <Link to="/co-robimy/strony-internetowe">
                  Strony internetowe
                </Link>
                <Link to="/co-robimy/sklepy-internetowe">
                  Sklepy internetowe
                </Link>
                <Link to="/co-robimy/obsluga-stron-internetowych">
                  Obsługa stron internetowych
                </Link>
                <Link to="/co-robimy/funkcjonalności">
                  Funkcjonalności internetowe
                </Link>
                <Link to="/co-robimy/optymalizacja">
                  Optimalizacja stron WWW
                </Link>
                <Link to="/body-leasing">Body-leasing</Link>
              </div>
            ) : null}
          </div>
          <div className="sections-navigation">
            {generateSectionNavigation()}
            <a href="#kontakt">Kontakt</a>
          </div>
        </div>
      </div>
      {generateSections()}
      <section id="kontakt">
        <div className="container contact-container">
          <div className="contact-header">
            <p className="section-header">Zapytaj o oferę</p>
          </div>
          <div className="contact-infos">
            <div className="infos-element icon-text">
              <FontAwesomeIcon icon={faMap} />
              <p>ul. Karola Olszewskiego 6, 25-663 Kielce</p>
            </div>
            <div className="infos-element icon-text">
              <FontAwesomeIcon icon={faEnvelope} />
              <p>
                <a href="mailto:hello@coderhino.cc">hello@coderhino.cc</a>
              </p>
            </div>
            <div className="infos-element icon-text">
              <FontAwesomeIcon icon={faClock} />
              <p>Poniedziałek - Sobota: 9:00 - 18:00</p>
            </div>
            <Link to="/kontakt" className="contact-infos-button">
              <span>Skontaktuj się z nami</span>
              <span>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="arrow-right-long"
                  class="svg-inline--fa fa-arrow-right-long "
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
                  ></path>
                </svg>
              </span>
            </Link>
          </div>
          <div>
            <ContactForm />
          </div>
        </div>
      </section>
    </main>
  );
}

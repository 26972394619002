import { useEffect, useRef } from "react";

import { gsap } from "gsap";

import {
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AboutSection(props) {

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.fromTo(".about-infos", { x: -400, opacity: .3 }, 
      { x: 0, opacity: 1, duration: 1,
        scrollTrigger: {
          trigger: document.getElementById("about"), 
          start: "top bottom",
          scrub: false, 
          once: true
        } 
      })
    });

    return () => ctx.revert()
  }, [])

  return (
    <section id="about">
      <div className="container about-container" ref={props.aboutRef}>
        <div className="about-image">
          <img
              src={window.location.origin + "/img/about-photo.png"}
              alt="about-photo"
            />
        </div>
        <div className="about-infos">
          <p className="section-header-red">O nas</p>
          <p className="big-section-header">
            Jesteśmy zespołem, który nie boi się wyzwań.
          </p>
          <p className="about-text">
            W Wordpressie mamy wieloletnie doświadczenie. Pracowaliśmy jako
            podwykonawcy nad stronami <span>dużych marek</span>, i tych... mniejszych. Jako
            podwykonawcy white-label nie wykorzystujemy dotychczasowych prac -
            nawet żeby uzyskać kolejne zlecenie! Nie musisz się bać wycieku
            informacji o Twojej realizacji. Chyba, że nam na to pozwolisz! :)
          </p>
          <a onClick={() => props.workflowRef.current.scrollIntoView({ behavior: "smooth" })} className="learn-more-button" aria-label="Dowiedz się więcej">
            <span>Dowiedz się więcej</span>
            <span>
              <FontAwesomeIcon icon={faArrowRightLong} />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
}

import { useEffect, useRef } from "react";

import SerivcePageTitleSection from "../../components/about-subpages/ServicePageTitle"
import ServicePageContent from "../../components/about-subpages/ServicePageContent"

import { Helmet } from "react-helmet";

export default function ECommercePage() {
    const pageData = {
        title: "Sklepy internetowe",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
        Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
        Praesent sollicitudin sit amet nisi eu commodo. 
        Vivamus lectus justo, dignissim.`,
        projects: [
            {
                id: 1,
                name: "Sklep internetowy z meblami dla dzieci",
                image: "/img/screens/zeebra.png",
                link: "https://zeebra.pl",
                category: ["sklepy internetowe"],
              },
              {
                id: 2,
                name: "Aplikacja do zamawiania wywozu nieczystości",
                image: "/img/screens/slonik.png",
                link: "https://slonik24.pl",
                category: ["dedykowane rozwiązania", "sklepy internetowe"],
              },
        ],
        faq: [
            {
                question: "Question 1",
                answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
                Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
                Praesent sollicitudin sit amet nisi eu commodo. 
                Vivamus lectus justo, dignissim.`,
            },
            {
                question: "Question 2",
                answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
                Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
                Praesent sollicitudin sit amet nisi eu commodo. 
                Vivamus lectus justo, dignissim.`,
            },
            {
                question: "Question 3",
                answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
                Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
                Praesent sollicitudin sit amet nisi eu commodo. 
                Vivamus lectus justo, dignissim.`,
            },
        ]
    }

    const headerRef = useRef(null);

    useEffect(() => {
        document.title = `CODERHINO - ${pageData.title}`;
    }, []); 

    return (
        <>
            <Helmet>
                <meta property="og:description" content="Nasze sklepy internetowe" />
            </Helmet>
            <SerivcePageTitleSection title={pageData.title} headerRef={headerRef} />
            <ServicePageContent pageData={pageData} headerRef={headerRef} />
        </>
    )
}
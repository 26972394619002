import { useState, useEffect } from "react";

import { gsap } from "gsap";

import useWindowDimensions from "../../hooks/useWindowDimensions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCompass } from "@fortawesome/free-regular-svg-icons";

export default function ChooseUsSections() {
  const [hoveredCard, setHoveredCard] = useState(0);

  const { width, height } = useWindowDimensions()

  useEffect(() => {
    let ctx = gsap.context(() => {
      if (width <= 992) {
        gsap.timeline({ scrollTrigger: {
          trigger: document.getElementById("choose-us-header"), 
          start: "top bottom",
          scrub: false, 
          once: true
        }})
        .fromTo(document.getElementById("work-time"), { y: -100, opacity: 0 }, { y: 0, opacity: 1 })
        .fromTo(document.getElementById("remote"), { y: -100, opacity: 0 }, { y: 0, opacity: 1 })
        .fromTo(document.getElementById("funding"), { y: -100, opacity: 0 }, { y: 0, opacity: 1 } )
      } else {
        gsap.timeline({ scrollTrigger: {
          trigger: document.getElementById("choose-us-cards"), 
          start: "bottom bottom",
          scrub: false, 
          once: true
        }})
        .fromTo(document.getElementById("remote"), { y: -100, opacity: 0 }, { y: 0, opacity: 1 })
        .fromTo(document.getElementById("work-time"), { y: -100, opacity: 0 }, { y: 0, opacity: 1 })
        .fromTo(document.getElementById("funding"), { y: -100, opacity: 0 }, { y: 0, opacity: 1 } )
      }
      
    });
    return () => ctx.revert()
  }, [])

  return (
    <section id="choose-us" className="choose-us-container">
      <div className="container">
        <p className="section-header choose-us-header" id="choose-us-header">Dlaczego nas wybrać?</p>
        <p className="choose-us-subheader">
          Dowiedz się dlaczego warto pracować z nami!
        </p>
        <div className="choose-us-cards" id="choose-us-cards">
          <div id="work-time"
            className={`${hoveredCard === 1 ? "hovered-card" : ""} choose-us-card`}
            onMouseEnter={() => setHoveredCard(1)}
            onMouseLeave={() => setHoveredCard(0)}
          >
            <div className="card-icon-red">
            <svg width="60" height="60" viewBox="0 0 43 41" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.7513 18.6294C18.6929 19.6626 18.6929 21.3374 19.7513 22.3698C20.809 23.4031 22.5254 23.4031 23.583 22.3698C24.6415 21.3365 24.6415 19.6618 23.583 18.6294C22.5245 17.5969 20.809 17.5969 19.7513 18.6294ZM21.6667 0C10.0685 0 0.666748 9.17788 0.666748 20.5C0.666748 31.8221 10.0685 41 21.6667 41C33.265 41 42.6667 31.8221 42.6667 20.5C42.6667 9.17788 33.265 0 21.6667 0ZM32.348 12.238L26.7618 24.1702C26.4916 24.7472 26.0175 25.21 25.4264 25.4737L13.2041 30.9269C11.7942 31.5559 10.3411 30.1375 10.9855 28.7612L16.5726 16.829C16.8428 16.252 17.3168 15.7892 17.9079 15.5254L30.1303 10.0723C31.5401 9.44405 32.9924 10.8617 32.348 12.238V12.238Z" fill="white"/>
            </svg>
            </div>
            <p className="card-title">Elastyczny czas pracy</p>
            <p className="card-text">
              Dla większości pozycji możemy zaoferować całkowicie elastyczny
              czas pracy. Jesteśmy skupieni na efektach Twojej pracy, a nie
              wysiadywaniu przed komputerem.
            </p>
          </div>
          <div id="remote"
            className={`${hoveredCard === 2 ? "hovered-card" : ""} choose-us-card`}
            onMouseEnter={() => setHoveredCard(2)}
            onMouseLeave={() => setHoveredCard(0)}
          >
            <div className="card-icon-purple">
              <svg width="60" height="60" viewBox="0 0 43 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.7513 18.6294C18.6929 19.6626 18.6929 21.3374 19.7513 22.3698C20.809 23.4031 22.5254 23.4031 23.583 22.3698C24.6415 21.3365 24.6415 19.6618 23.583 18.6294C22.5245 17.5969 20.809 17.5969 19.7513 18.6294ZM21.6667 0C10.0685 0 0.666748 9.17788 0.666748 20.5C0.666748 31.8221 10.0685 41 21.6667 41C33.265 41 42.6667 31.8221 42.6667 20.5C42.6667 9.17788 33.265 0 21.6667 0ZM32.348 12.238L26.7618 24.1702C26.4916 24.7472 26.0175 25.21 25.4264 25.4737L13.2041 30.9269C11.7942 31.5559 10.3411 30.1375 10.9855 28.7612L16.5726 16.829C16.8428 16.252 17.3168 15.7892 17.9079 15.5254L30.1303 10.0723C31.5401 9.44405 32.9924 10.8617 32.348 12.238V12.238Z" fill="white"/>
              </svg>
            </div>
            <p className="card-title">Praca zdalna</p>
            <p className="card-text">
              Pracujemy zdalnie. Teraz i zawsze. Czasem się spotkamy, ale
              wyłącznie integracyjnie. Możesz pracować z Azji albo sprzed
              kominka w Zakopanem. Liczy się dla nas efekt.
            </p>
          </div>
          <div id="funding"
            className={`${hoveredCard === 3 ? "hovered-card" : ""} choose-us-card`}
            onMouseEnter={() => setHoveredCard(3)}
            onMouseLeave={() => setHoveredCard(0)}
          >
            <div className="card-icon-blue">
              <svg width="60" height="60" viewBox="0 0 43 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.7513 18.6294C18.6929 19.6626 18.6929 21.3374 19.7513 22.3698C20.809 23.4031 22.5254 23.4031 23.583 22.3698C24.6415 21.3365 24.6415 19.6618 23.583 18.6294C22.5245 17.5969 20.809 17.5969 19.7513 18.6294ZM21.6667 0C10.0685 0 0.666748 9.17788 0.666748 20.5C0.666748 31.8221 10.0685 41 21.6667 41C33.265 41 42.6667 31.8221 42.6667 20.5C42.6667 9.17788 33.265 0 21.6667 0ZM32.348 12.238L26.7618 24.1702C26.4916 24.7472 26.0175 25.21 25.4264 25.4737L13.2041 30.9269C11.7942 31.5559 10.3411 30.1375 10.9855 28.7612L16.5726 16.829C16.8428 16.252 17.3168 15.7892 17.9079 15.5254L30.1303 10.0723C31.5401 9.44405 32.9924 10.8617 32.348 12.238V12.238Z" fill="white"/>
              </svg>
            </div>
            <p className="card-title">Dofinansowania</p>
            <p className="card-text">
              Dofinansowujemy sprzęt potrzebny do Twojej pracy: monitor,
              peryferia czy fotel. Ponadto wspieramy Twoje zdrowie -
              dofinansowujemy okulary korekcyjne i ochronne do pracy!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

import RealizationsDisplay from "./RealizationsDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function RealizationsSection() {
  return (
    <section id="realizations">
      <div className="container realizations-container">
        <p className="section-header-red">NASZE REALIZACJE</p>
        <p className="section-header">Zobacz co możemy Ci pokazać</p>
        <RealizationsDisplay />

        <Link to={"/kontakt"} className="realizations-red-button" aria-label="Dowiedz się więcej">
            Skontaktuj się z nami
            <FontAwesomeIcon icon={faArrowRightLong} />
        </Link>
      </div>
    </section>
  );
}

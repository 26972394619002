import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { gsap } from "gsap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";

import {
  faEnvelope,
  faClock,
} from "@fortawesome/free-regular-svg-icons";

import {
  faPhone,
  faMap,
} from "@fortawesome/free-solid-svg-icons";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import useWindowDimensions from "../hooks/useWindowDimensions";

export default function Footer() {
  const [showHeadquaters, setShowHeadquaters] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showLinks, setShowLinks] = useState(false);

  const { height, width } = useWindowDimensions();

  function hideSection(section) {
    let ctx = gsap.context(() => {
      gsap.timeline()
      .to(`.${section}-button`, { rotate: 0 }, 0)
      .fromTo(`.${section}-infos`, { y: 0, opacity: 1 }, { y: -40, opacity: 0 }, 0)
      .add(() => {
        if (section === "headquaters") setShowHeadquaters(false)
        if (section === "footer-about") setShowAbout(false)
        if (section === "links") setShowLinks(false)
      })
    });
    return () => ctx.revert()
  }  

  useEffect(() => {
    let ctx = gsap.context(() => {
      if (showHeadquaters) {
        gsap.timeline()
        .to(".headquaters-button", { rotate: 180 }, 0)
        .fromTo(".headquaters-infos", { y: -40, opacity: .5 }, { y: 0, opacity: 1 }, 0)
      }
    });
    return () => ctx.revert()
  }, [showHeadquaters])

  useEffect(() => {
    let ctx = gsap.context(() => {
      if (showAbout) {
        gsap.timeline()
        .to(".footer-about-button", { rotate: 180 }, 0)
        .fromTo(".footer-about-infos", { y: -40, opacity: .5 }, { y: 0, opacity: 1 }, 0)
      }
    });
    return () => ctx.revert()
  }, [showAbout])

  useEffect(() => {
    let ctx = gsap.context(() => {
      if (showLinks) {
        gsap.timeline()
        .to(".links-button", { rotate: 180 }, 0)
        .fromTo(".links-infos", { y: -40, opacity: .5 }, { y: 0, opacity: 1 }, 0)
      }
    });
    return () => ctx.revert()
  }, [showLinks])
  

  return (
    <footer className="footer-container">
      <div className="container footer-infos">
        <div>
          <div>
            <p className="infos-header">
              Nasza siedziba
              {width < 992 ? (
                <button
                  aria-label={ showHeadquaters ? "Schowaj informacje kontaktowe" : "Pokaż informacje kontaktowe"}
                  onClick={() => showHeadquaters ? hideSection("headquaters") : setShowHeadquaters(true)}
                  className="footer-button headquaters-button"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              ) : null}
            </p>
          </div>
          {showHeadquaters || width > 992 ? (
            <div className="headquaters-infos">
              <div className="infos-element icon-text">
                <FontAwesomeIcon icon={faMap} />
                <p>ul. Karola Olszewskiego 6, 25-663 Kielce</p>
              </div>
              <div className="infos-element icon-text">
                <FontAwesomeIcon icon={faEnvelope} />
                <p><a href="mailto:hello@coderhino.cc">hello@coderhino.cc</a></p>
              </div>
              <div className="infos-element icon-text">
                <FontAwesomeIcon icon={faClock} />
                <p>Poniedziałek - Sobota: 9:00 - 18:00</p>
              </div>
            </div>
          ) : null}
        </div>
        <div>
          <div>
            <p className="infos-header">
              O nas
              {width < 992 ? (
                <button
                  aria-label={ showAbout ? "Schowaj informacje o nas" : "Pokaż informacje o nas"}
                  onClick={() => showAbout ? hideSection("footer-about") : setShowAbout(true)}
                  className="footer-button footer-about-button"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              ) : null}
            </p>
          </div>
          {showAbout || width > 992 ? (
            <div className="footer-about-infos">
              <div className="infos-element">
                <p><Link to="/o-nas" aria-label="Pokaż informacje o nas">O nas</Link></p>
              </div>
              <div className="infos-element">
                <p><Link to="/faq" aria-label="Pokaż F.A.Q">F.A.Q.</Link></p>
              </div>
              <div className="infos-element">
                <p><Link to="/kariera" aria-label="Pokaż możliwości kariery">Kariera</Link></p>
              </div>
            </div>
          ) : null}
        </div>
        <div>
          <div>
            <p className="infos-header">
              Przydatne linki
              {width < 992 ? (
                <button
                  aria-label={ showLinks ? "Schowaj przydatne linki" : "Pokaż przydatne linki"}
                  onClick={() => showLinks ? hideSection("links") : setShowLinks(true)}
                  className="footer-button links-button"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              ) : null}
            </p>
          </div>
          {showLinks || width > 992 ? (
            <div className="links-infos">
              <div className="infos-element">
                <p><Link to="/konsultacja-projektu" aria-label="Skonsultuj projekt">Konsultacja projektu</Link></p>
              </div>
              <div className="infos-element">
                <p><Link to="/kontakt" aria-label="Skontaktuj się">Kontakt</Link></p>
              </div>
              <div className="infos-element">
                <p><Link to="/jak-to-dziala" aria-label="Dowiedz się o naszej pracy">Jak pracujemy?</Link></p>
              </div>
              <div className="infos-element">
                <p><Link to="/rozliczenia" aria-label="Dowiedz się jak się rozliczamy">Sposoby rozliczenia</Link></p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="number-icons-div">
          <div className="icon-text icon-phone-text">
            <FontAwesomeIcon icon={faPhone} />
            <p>
              Dowiedz się więcej <br />
              <span className="phone-number"><a href="tel:+48 41 242 14 06">+48 41 242 14 06</a></span>
            </p>
          </div>
          <div className="social-media-buttons">
            <a href="https://www.facebook.com/coderhino" target="_blank" className="footer-button" aria-label="Link do Facebooka">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://www.linkedin.com/company/coderhino/" target="_blank" className="footer-button" aria-label="Link do LinkedIn">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </div>
        </div>
      </div>
      <hr className="footer-line" />
      <div className="container footer-clauses">
        <div>
          <p>Copyright © 2023. All Rights Reserved.</p>
        </div>
        <div>
          <Link to={"/"}>
            <img
              src={window.location.origin + "/img/coderhino-logo-white.png"}
              alt="coderhino-logo"
              className="footer-logo"
              aria-label="Strona główna"
            />
          </Link>
        </div>
        <div className="clauses-links">
          <p><Link to="/polityka-prywatnosci" aria-label="Dowiedz się o politycy prywatności">Polityka prywatności</Link></p>
          <p><Link to="/cookies" aria-label="Dowiedz się o cookies">Informacja o cookie</Link></p>
        </div>
      </div>
    </footer>
  );
}

import { useState } from "react";

import axios from "axios";

import CustomInput from "../components/form/CustomInput";
import CustomTextarea from "../components/form/CustomTextarea";
import CustomCheckbox from "./form/CustomCheckbox";

export default function ContactForm() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [question, setQuestion] = useState('');
    const [agreement, setAgreement] = useState(false);
    const [nameInvalid, setNameInvalid] = useState("");
    const [emailInvalid, setEmailInvalid] = useState("");
    const [questionInvalid, setQuestionInvalid] = useState("");
    const [agreementInvalid, setAgreementInvalid] = useState("");

    const handleSubmit = (e) => {
        let valid = true;
        e.preventDefault();
        if (!name) {
            setNameInvalid("Imię nie może być puste");
            valid = false;
        }
        if (!question) {
            setQuestionInvalid("Pytanie nie może być puste")
            valid = false;
        }
        if (!email) {
            setEmailInvalid("Email nie może być pusty")
            valid = false;
        } else {
            if (!new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$").test(email)) {
                setEmailInvalid("Email nie spełnia wymagań")
                valid = false;
            }
        }
        if (!agreement) {
            setAgreementInvalid("Musisz zaznaczyć zgodę");
            valid = false;
        }
        if (valid) {
            console.log(`valid`);
            axios.post(`http://127.0.0.1:8080/email`, {
                emailName: name,
                email: email,
                emailQuestion: question,
                emailFile: e.target.file.value
            })
            .then(response => {
              console.log(response);
            }) 
            .catch(error => console.error(error));
        }
    }

    const handleNameChange = (e) => {
        const value = e.target.value;
        setName(value.trim())
        setNameInvalid("")
    }

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value.trim())
        setEmailInvalid("")
    }

    const handleQuestionChange = (e) => {
        const value = e.target.value;
        setQuestion(value.trim())
        setQuestionInvalid("")
    }

    const handleAgreementChange = (e) => {
        const value = e.target.checked;
        setAgreement(value);
        setAgreementInvalid("");
    }

    return (
        <form onSubmit={handleSubmit} onReset={() => {setEmail(""); setName(""); setQuestion(""); setEmailInvalid(""); setNameInvalid(""); setQuestionInvalid("")}}>
                    <CustomInput type="text" id="name" title="Imię" invalid={nameInvalid} placeholder="Podaj imię..." onChange={handleNameChange} />
                    <CustomInput type="text" id="email" title="E-mail" invalid={emailInvalid} placeholder="Podaj e-mail..." onChange={handleEmailChange} />
                    <CustomTextarea id="question" title="Treść pytania" invalid={questionInvalid} placeholder="Podaj treść pytania..." onChange={handleQuestionChange} />
                    <input type="file" id="file" />
                    <CustomCheckbox id="agreement" title="Zgadzam się na wykorzystywanie moich danych..." agreement={agreement} invalid={agreementInvalid} onChange={handleAgreementChange}  />
                    <div className="form-buttons">
                        <input type="reset" />
                        <input type="submit" />
                    </div>
                </form>
    )
}
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

export default function ClientsSwiper(props) {

    function generateSlides() {

        let elements = [];

        for (const client of props.clients) {
            elements.push(
                <SwiperSlide>
                    { client }
                </SwiperSlide>
            )
        }

        return elements;
    }

  return (
  <Swiper slidesPerView={2}
  initialSlide={1}
  spaceBetween={20}
  centeredSlides={false}
  className="client-swiper">
    { generateSlides() }
  </Swiper> 
  )
}

import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

export default function HeaderSwiper() {

  const pagination = {
    el: '.header-swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + ' pagination-rectangle"></span>';
    },
  };

  return (
    <Swiper pagination={pagination} modules={[Pagination]} className="header-swiper">
        <SwiperSlide><img src={window.location.origin + "/img/header-photo.avif"} alt="" /></SwiperSlide>
        <SwiperSlide><img src={window.location.origin + "/img/header-photo.avif"} alt="" /></SwiperSlide>
        <SwiperSlide><img src={window.location.origin + "/img/header-photo.avif"} alt="" /></SwiperSlide>
        <div className="header-swiper-background-left">
            <p className="header-swiper-text-upper">Nie bój się nam zaufać.</p>
            <p className="header-swiper-text-lower">Popracujmy razem.</p>
        </div>
        <div className="header-swiper-background-right">
            <div className="header-swiper-pagination">
                
            </div>
        </div>
      </Swiper>
  );
}

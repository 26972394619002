import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"

import axios from 'axios';

export default function PostPage() {

    const [isInWordPress, setIsInWordPress] = useState(false);
    const [postData, setPostData] = useState();

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`https://wp.coderhino.cc//wp-json/wp/v2/posts?slug=${params.name}`)
          .then(response => {
            if (response.data.length) {
                setIsInWordPress(true)
                setPostData(response.data[0])
            } else {
                navigate("/nie-znaleziono");
            }
          }) 
          .catch(error => console.error(error));
    }, [])
    
    return (
        <div className="container wp-page-container">
            { isInWordPress ? (
                <div dangerouslySetInnerHTML={{__html: postData.content.rendered}} />
            ) : (<p>
                Ładowanie...
            </p>)}
        </div>
    )
}
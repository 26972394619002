export default function RealizationsModal(props) {
    return (
        <div className="modal-background" onClick={props.hideModalFunction}>
            <div className="modal realizations-modal">
                <button onClick={props.hideModalFunction}>
                    X
                </button>
                { props.modalData.id }
            </div>  
        </div>
    )
}
import { useEffect } from "react";

import PageTitleSection from "../components/PageTitleSection"
import ChooseUsSections from "../components/carrer-page/ChooseUsSection"
import OffersSection from "../components/carrer-page/OffersSection"
import StepsSection from "../components/carrer-page/StepsSection"

export default function CarrerPage() {

    useEffect(() => {
        document.title = `CODERHINO - Kariera`;
    }, []);

    return (
        <main>
            <PageTitleSection title="Kariera" />
            <OffersSection />
            <ChooseUsSections />
            <StepsSection />
        </main>
    )
}
export default function AgreementModal(props) {
    return (
        <div className="modal-background">
            <div className="modal agreement-modal">
                <p>
                    COOKIES I POLITYKA PRYWATNOSCI
                </p>
                <div className="buttons">
                    <button onClick={props.rejectCookies}>
                        ODRZUCAM
                    </button>
                    <button onClick={props.acceptCookies}>
                        AKCEPTUJĘ
                    </button>
                </div>
            </div>  
        </div>
    )
}
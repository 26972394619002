import { useState, useEffect } from "react";

import RealizationDisplay from "./RealizationDisplay";
import RealizationsModal from "../modals/RealizationsModal";

import { capitalizeFirstLetter } from "../../utilities/textFunctions";

export default function RealizationsDisplay() {
  const [selectedCategory, setSelectedCategory] = useState("wszystko");
  const [realizations, setRealizations] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    setRealizations([
      {
        id: 1,
        name: "Sklep internetowy z meblami dla dzieci",
        image: "/img/screens/zeebra.png",
        link: "https://zeebra.pl",
        category: ["sklepy internetowe"],
      },
      {
        id: 2,
        name: "Aplikacja do zamawiania wywozu nieczystości",
        image: "/img/screens/slonik.png",
        link: "https://slonik24.pl",
        category: ["dedykowane rozwiązania", "sklepy internetowe"],
      },
      {
        id: 3,
        name: "Serwis VOD o tematyce medycznej",
        image: "/img/screens/tzf.png",
        link: "https://cms.tzf.health",
        category: ["strony internetowe"],
      },
      {
        id: 4,
        name: "Strona internetowa agencji reklamowej",
        image: "/img/screens/mediagnoza.png",
        link: "https://mediagnoza.net",
        category: ["strony internetowe"],
      },
      {
        id: 5,
        name: "Portal internetowy o portfelach elektronicznych",
        image: "/img/screens/zaplacone.png",
        link: "https://zaplac.one",
        category: ["strony internetowe"],
      },
      {
        id: 6,
        name: "Aplikcja do administracji turnieju w grę online",
        image: "/img/screens/no-screen.png",
        link: "https://www.google.com",
        category: ["dedykowane rozwiązania"],
      },
      {
        id: 7,
        name: "Strona internetowa agencji SEO",
        image: "/img/screens/digibutsu.png",
        link: "https://digibutsu.com/",
        category: ["strony internetowe"],
      },
      {
        id: 8,
        name: "Strona internetowa firmy realizującej transmisję na żywo",
        image: "/img/screens/witchhub.png",
        link: "https://wh.dev.coderhino.cc",
        category: ["strony internetowe"],
      },
      {
        id: 9,
        name: "Portal internetowy - słownik mediów społecznościowych",
        image: "/img/screens/slownik.png",
        link: "https://slowniksm.pl/",
        category: ["strony internetowe"],
      },

    ]);
  }, []);

  function generateCategoriesSelect() {
    let elements = [];

    const categories = [
      "wszystko",
      "strony internetowe",
      "sklepy internetowe",
      "dedykowane rozwiązania",
    ];

    for (const category of categories) {
      elements.push(
        <div key={category}>
          <input
            type="radio"
            name="categories"
            id={category}
            checked={category === selectedCategory}
            onChange={() => setSelectedCategory(category)}
          />
          <label className="realization-category-select-label" 
          htmlFor={category}>
            <div className="realization-category-select-underline"></div>
            {capitalizeFirstLetter(category)}
          </label>
        </div>
      );
    }

    return elements;
  }

  function generateRealizations() {
    let elements = [];

    let i = 0;
    for (const realization of realizations) {

      if (realization.category.includes(selectedCategory) || selectedCategory === "wszystko") {
        i++;
        if(i > 6) {
          break;
        }
        elements.push(
            <div>
                <RealizationDisplay realization={realization} showModalFunction={showModalFunction} />
            </div>
        );
      }
    }

    return elements;
  }

  function showModalFunction(data) {
    setModalData(data);
    setShowModal(true);
  }

  function hideModalFunction() {
    setShowModal(false);
    setModalData({})
  }

  return (
    <div>
      { showModal ? (
        <RealizationsModal modalData={modalData} hideModalFunction={hideModalFunction} />
      ) : (null)}
      <div className="realization-category-select">{generateCategoriesSelect()}</div>
      <div className="realizations-display">{generateRealizations()}</div>
    </div>
  );
}

import { useEffect } from "react";

import PageTitleSection from "../components/PageTitleSection"
import ServicesChoice from "../components/about-page/ServicesChoice"

export default function AboutPage() {

    useEffect(() => {
        document.title = `CODERHINO - Co robimy?`;
    }, []);

    return (
        <main>
            <PageTitleSection title={"Co robimy?"} />
            <ServicesChoice />
        </main>
    )
}
import { useEffect, useState } from "react";

import axios from "axios";

import PageTitleSection from "../components/PageTitleSection"

export default function CookiesPage() {

    const [pageData, setPageData] = useState();
    const [ready, setReady] = useState(false);

    useEffect(() => {
        document.title = `CODERHINO - Cookies`;
        axios.get(`http://wp.coderhino.cc/wp-json/wp/v2/pages?slug=cookies`)
          .then(response => {
            setPageData(response.data[0]);
            setReady(true)
          }) 
          .catch(error => console.error(error));
    }, []);

    return (
        <>
            <PageTitleSection title="Cookies" />
            <div className="container">
                {ready ? (
                    <div dangerouslySetInnerHTML={{__html: pageData.content.rendered  }} />
                ) : (null)}
            </div>
        </>
    )
}
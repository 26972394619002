import { useEffect } from "react";

import { gsap } from "gsap";

import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function PossibilitiesSection() {

    const { width, height } = useWindowDimensions();

    useEffect(() => {
        let ctx = gsap.context(() => {
          if (width <= 992) {
            gsap.timeline({ scrollTrigger: {
              trigger: document.getElementById("possibilities"), 
              start: "top bottom",
              scrub: false, 
              once: true,
            }})
            .fromTo(".possibilities-infos-upper", { x: -300, opacity: .5 }, { x: 0, opacity: 1, duration: 1 })
            .fromTo(".possibilities-infos-lower-1", { x: -300, opacity: .5 }, { x: 0, opacity: 1, duration: 1, })
            .fromTo(".possibilities-infos-lower-2", { x: -300, opacity: .5 }, { x: 0, opacity: 1, duration: 1, })
          } else {
            gsap.timeline({ scrollTrigger: {
              trigger: document.getElementById("possibilities"), 
              start: "top bottom",
              scrub: false, 
              once: true,
            }})
            .fromTo(".possibilities-infos-upper", { x: -300, opacity: .5 }, { x: 0, opacity: 1, duration: 1,  })
            .fromTo(".possibilities-infos-lower", { x: -300, opacity: .5 }, { x: 0, opacity: 1, duration: 1,  })
          }
      });
      return () => ctx.revert()
        
      }, [])

    return (
        <section id="possibilities" className="container possibilities-container">
            <div className="possibilities-infos">
                <div className="possibilities-infos-upper">
                    <p className="section-header">
                    Sprzedawaj strony internetowe i poszerz swoje możliwości!
                    </p>
                    <p className="possibilities-infos-text">
                    Jesteśmy w stanie pracować dla Ciebie... a w zasadzie - dla Twojego klienta. Nie odróżni nas od działań Twojej firmy. Nie chwalimy się naszymi realizacjami. Jesteśmy całkowicie white-label.
                    </p>
                </div>
                <div className="possibilities-infos-lower">
                    <div className="possibilities-infos-lower-1">
                        <p className="possibilities-infos-subheader">
                        Rozwiń swoją firmę
                        </p>
                        <p className="possibilities-infos-text">
                        Masz małą agencję reklamową? A może nie wyrabiasz się z terminami? Nie trać zleceń - zainwestuj w podwykonawców.
                        </p>
                    </div>
                    <div className="possibilities-infos-lower-2">
                        <p className="possibilities-infos-subheader">
                        Sprzedawaj coś nowego
                        </p>
                        <p className="possibilities-infos-text">
                        Jesteś świetnym sprzedawcą? Sprzedawaj strony internetowe bez wiedzy programistycznej i zarabiaj!
                        </p>
                    </div>
                </div>
            </div>
            <div>
            <img
              src={window.location.origin + "/img/sample-possibilities.png"}
            />
            </div>
        </section>
    )
}
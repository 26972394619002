import { useEffect, useRef } from "react";

import SerivcePageTitleSection from "../../components/about-subpages/ServicePageTitle"
import ServicePageContent from "../../components/about-subpages/ServicePageContent"
import { Helmet } from "react-helmet";

export default function WebsitesPage() {
    const pageData = {
        title: "Strony internetowe",
        header: `Nasze strony internetowe odpowiadają Twoim klientom`,
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
        Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
        Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
        Praesent sollicitudin sit amet nisi eu commodo. 
        Vivamus lectus justo, dignissim.`,
        projects: [
            {
                id: 3,
                name: "Serwis VOD o tematyce medycznej",
                image: "/img/screens/tzf.png",
                link: "https://cms.tzf.health",
                category: ["strony internetowe"],
              },
              {
                id: 4,
                name: "Strona internetowa agencji reklamowej",
                image: "/img/screens/mediagnoza.png",
                link: "https://mediagnoza.net",
                category: ["strony internetowe"],
              },
              {
                id: 5,
                name: "Portal internetowy o portfelach elektronicznych",
                image: "/img/screens/zaplacone.png",
                link: "https://zaplac.one",
                category: ["strony internetowe"],
              },
        ],
        faq: [
            {
                question: "Jak to robimy?",
                answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
                Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
                Praesent sollicitudin sit amet nisi eu commodo. 
                Vivamus lectus justo, dignissim.`,
                shown: false
            },
            {
                question: "Question 2",
                answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
                Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
                Praesent sollicitudin sit amet nisi eu commodo. 
                Vivamus lectus justo, dignissim.`,
                shown: false
            },
            {
                question: "Question 3",
                answer: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                Cras tempus elit ac ante vehicula mollis. Vivamus sed risus non tellus elementum malesuada. 
                Nullam sit amet dolor a metus scelerisque facilisis eget in orci. Nulla sagittis a nibh vitae tempor. 
                Praesent sollicitudin sit amet nisi eu commodo. 
                Vivamus lectus justo, dignissim.`,
                shown: false
            },
        ],
        customSections: [
            {
                order: 2,
                name: "Custom 1",
                section: <section id="custom-1">
                    <div className="container">
                        <div>
                            <p className="section-header">
                                Custom 1
                            </p>
                        </div>
                        <div>
                            <p>
                                Lorem ipsum dolor sit amet, 
                                consectetur adipiscing elit.
                                Morbi accumsan maximus neque, 
                                sit amet porttitor elit euismod vitae. 
                                Nullam quam metus, egestas at varius vitae, porta commodo nisi. 
                                Nam sit amet nulla vitae metus bibendum varius eget et dui. 
                                Etiam sit amet massa lacinia, posuere ex non, efficitur dui. 
                                Mauris bibendum purus eget massa venenatis, quis viverra nunc vestibulum. 
                                Morbi non efficitur lacus. Integer eget lobortis justo. Sed eleifend libero nec magna euismod mattis. 
                                Vestibulum sem risus, consectetur eu malesuada quis, mattis sit amet tellus. In et ligula porta, tempus nulla et, rhoncus magna. 
                                Ut mollis fermentum iaculis. Aenean ac gravida massa.
                                Vivamus aliquet condimentum rutrum. Sed dui erat, vulputate non sapien sed, malesuada rutrum nulla. 
                                Vivamus iaculis at odio eget lobortis. Ut viverra sit amet ipsum vitae consectetur. 
                                Etiam lobortis aliquam massa, in tristique ligula congue vitae. Pellentesque tellus erat, faucibus aliquam mi nec, tristique ullamcorper libero. 
                                Phasellus lacinia tortor ut turpis dignissim, sit amet laoreet turpis placerat. 
                                Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Proin placerat in sapien vel elementum. Nunc viverra nunc in mattis ullamcorper. Sed condimentum, nunc a dictum venenatis, sem nisl scelerisque metus, quis blandit felis nisl eu est.
                            </p>
                        </div>
                    </div>
                </section>
            },
            {
                order: 5,
                name: "Custom 2",
                section: <section id="custom-2">
                    <div className="container">
                        <div>
                            <p className="section-header">
                                Custom 2
                            </p>
                        </div>
                        <div>
                            <div>
                                <p>
                                Etiam sit amet massa lacinia, posuere ex non, efficitur dui. 
                                Mauris bibendum purus eget massa venenatis, quis viverra nunc vestibulum. 
                                Morbi non efficitur lacus. Integer eget lobortis justo. Sed eleifend libero nec magna euismod mattis. 
                                Vestibulum sem risus, consectetur eu malesuada quis, mattis sit amet tellus. In et ligula porta, tempus nulla et, rhoncus magna. 
                                Ut mollis fermentum iaculis. Aenean ac gravida massa.
                                Vivamus aliquet condimentum rutrum. Sed dui erat, vulputate non sapien sed, malesuada rutrum nulla. 
                                Vivamus iaculis at odio eget lobortis. Ut viverra sit amet ipsum vitae consectetur. 
                                Etiam lobortis aliquam massa, in tristique ligula congue vitae. Pellentesque tellus erat, faucibus aliquam mi nec, tristique ullamcorper libero. 
                                Phasellus lacinia tortor ut turpis dignissim, sit amet laoreet turpis placerat. 
                                Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Proin placerat in sapien vel elementum. Nunc viverra nunc in mattis ullamcorper. Sed condimentum, nunc a dictum venenatis, sem nisl scelerisque metus, quis blandit felis nisl eu est.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            },
        ]
    }

    const headerRef = useRef(null);

    useEffect(() => {
        document.title = `CODERHINO - ${pageData.title}`;
    }, []);    

    return (
        <>
            <Helmet>
                <meta property="og:description" content="Nasze strony internetowe" />
            </Helmet>
            <SerivcePageTitleSection title={pageData.title} headerRef={headerRef} />
            <ServicePageContent pageData={pageData} headerRef={headerRef} />
        </>
    )
}
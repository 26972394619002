import { useEffect } from "react";

import PageTitleSection from "../components/PageTitleSection";
import HowItsWorkingSection from "../components/htw-page/HowItsWorkingSection";
import PossibilitiesSection from "../components/htw-page/PossibilitiesSection";
import ProsSection from "../components/htw-page/ProsSection";

export default function HowItsWorkingPage() {

    useEffect(() => {
        document.title = `CODERHINO - Jak to działa?`;
    }, []);

    return (
        <>
            <PageTitleSection title="Ty sprzedajesz. My robimy. Jak to działa?" />
            <PossibilitiesSection />
            <HowItsWorkingSection />
            <ProsSection />
        </>
    )
}
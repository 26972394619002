import { useState, useEffect } from "react";

export default function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(function() {
    function updatePosition() {
      setScrollPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return function() {
      window.removeEventListener("scroll", updatePosition);
    };
  }, []);

  return scrollPosition;
}
import { useEffect } from "react";

import PageTitleSection from "../components/PageTitleSection"

export default function FAQPage() {

    useEffect(() => {
        document.title = `CODERHINO - FAQ`;
    }, []);

    return (
        <>
            <PageTitleSection title="FAQ" />
            <div className="container">
                FAQ PAGE
            </div>
        </>
    )
}
export default function CustomCheckbox(props) {
    return (
        <div className="form-element">
            <input
              type="checkbox"
              id="full-time"
              checked={props.agreement}
              onChange={props.onChange}
            />
            <label className="agreement-label" htmlFor="full-time">
              {props.agreement ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23.3325 6.86729C22.35 4.79073 20.794 3.04698 18.8337 1.82354C16.1072 0.12667 12.8778 -0.412392 9.74698 0.309483C6.61621 1.02667 3.95082 2.92042 2.24911 5.63917C0.542697 8.35792 0.00209773 11.5735 0.72603 14.7001C1.44996 17.822 3.34911 20.4798 6.07091 22.1767C7.98886 23.372 10.1889 24.0048 12.4406 24.0048H12.5863C14.8286 23.9767 17.0098 23.3298 18.8996 22.1392C19.4496 21.7923 19.6094 21.0704 19.2615 20.522C18.9137 19.9735 18.1897 19.8142 17.6397 20.161C16.1214 21.122 14.3632 21.6423 12.5581 21.6657C10.706 21.6892 8.89613 21.1782 7.31664 20.1985C5.12134 18.8298 3.59356 16.6923 3.01535 14.1798C2.43714 11.6673 2.86962 9.07979 4.24227 6.89073C7.07219 2.37667 13.0611 0.998545 17.588 3.82042C19.1675 4.8048 20.4179 6.20636 21.2077 7.87511C21.9786 9.50167 22.2795 11.3064 22.0773 13.0876C22.0068 13.7298 22.4675 14.311 23.1162 14.3814C23.7603 14.4517 24.3432 13.9923 24.4137 13.3454C24.6628 11.1282 24.2867 8.88761 23.3325 6.86729Z"
                    fill="#AF272F"
                  />
                  <path
                    d="M16.338 7.93135L10.6688 13.5845L8.56748 11.4892C8.10679 11.0298 7.36406 11.0298 6.90337 11.4892C6.44269 11.9485 6.44269 12.6892 6.90337 13.1485L9.83671 16.0735C10.067 16.3032 10.3679 16.4157 10.6688 16.4157C10.9696 16.4157 11.2705 16.3032 11.5008 16.0735L17.9974 9.59072C18.4581 9.13135 18.4581 8.39072 17.9974 7.93135C17.5367 7.47666 16.794 7.47666 16.338 7.93135Z"
                    fill="#AF272F"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.9162 6.86729C21.9337 4.79073 20.3777 3.04698 18.4175 1.82354C15.691 0.12667 12.4615 -0.412392 9.33072 0.309483C6.19995 1.02667 3.53456 2.92042 1.83285 5.63917C0.126437 8.35792 -0.414162 11.5735 0.309771 14.7001C1.0337 17.822 2.93285 20.4798 5.65465 22.1767C7.5726 23.372 9.7726 24.0048 12.0243 24.0048H12.17C14.4124 23.9767 16.5936 23.3298 18.4833 22.1392C19.0333 21.7923 19.1931 21.0704 18.8453 20.522C18.4974 19.9735 17.7735 19.8142 17.2235 20.161C15.7051 21.122 13.947 21.6423 12.1418 21.6657C10.2897 21.6892 8.47987 21.1782 6.90038 20.1985C4.70508 18.8298 3.1773 16.6923 2.59909 14.1798C2.02088 11.6673 2.45336 9.07979 3.82601 6.89073C6.65593 2.37667 12.6448 0.998545 17.1718 3.82042C18.7512 4.8048 20.0017 6.20636 20.7914 7.87511C21.5624 9.50167 21.8632 11.3064 21.6611 13.0876C21.5906 13.7298 22.0513 14.311 22.7 14.3814C23.344 14.4517 23.9269 13.9923 23.9974 13.3454C24.2466 11.1282 23.8705 8.88761 22.9162 6.86729Z"
                    fill="#AF272F"
                    fill-opacity="0.4"
                  />
                </svg>
              )}
              { props.title }
            </label>
            {
                props.invalid ? (
                    <div class="form-element-invalid checkbox">
                        {props.invalid}
                    </div>
                ) : (null)
            }
        </div>
    )
}